@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
/*
* Custom
*/
@import url(partials/reset.css);
@import url(partials/bootstrap.min.css);
@import url(partials/font-awesome.css);
@import url(partials/owl.carousel.css);
@import url(partials/imagelightbox.css);
/* Fonts */
/* Mixins */
@import url(partials/animate.css);
@import url("https://fonts.googleapis.com/css?family=Marcellus|Oswald|PT+Serif:400,400i");
@keyframes shake {
  0%, 100% {
    margin-left: 0px; }
  50% {
    margin-left: -5px; } }

@keyframes shake2 {
  0%, 100% {
    margin-right: 0px; }
  50% {
    margin-right: -5px; } }

/* Fonts */
/* Mixins */
@keyframes shake {
  0%, 100% {
    margin-left: 0px; }
  50% {
    margin-left: -5px; } }

@keyframes shake2 {
  0%, 100% {
    margin-right: 0px; }
  50% {
    margin-right: -5px; } }

/* Fonts */
/*
font-family: 'Oswald', sans-serif;
font-family: 'Marcellus', serif;
font-family: 'PT Serif', serif;
*/
/* Media Screens */
html, body {
  width: 100%;
  margin: 0;
  padding: 0; }

body {
  font-size: 18px;
  line-height: 1.4;
  color: #292b2e;
  font-weight: 400;
  font-family: "Marcellus", serif;
  background: #0e0e0e; }
  @media (max-width: 1329px) {
    body {
      font-size: 15px; } }
  @media (max-width: 479px) {
    body {
      min-width: 320px;
      font-size: 14px; } }

.site-container {
  position: relative;
  z-index: 9;
  width: 1600px;
  margin: 0 auto; }
  @media (min-width: 1600px) {
    body .site-container {
      width: 1600px; } }
  @media (min-width: 1330px) {
    .site-container {
      width: 100%; } }
  @media (max-width: 1329px) {
    .site-container {
      width: 100%; } }
  @media (max-width: 767px) {
    .site-container {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 479px) {
    .site-container {
      width: 100%;
      min-width: 320px; } }

@media (min-width: 1330px) {
  .container {
    width: 1270px; } }

/**   nav menu  **/
#nav {
  z-index: 10;
  position: relative;
  text-align: center;
  display: inline; }
  @media (max-width: 767px) {
    #nav {
      display: none; } }
  #nav:before, #nav:after {
    content: " ";
    display: table; }
  #nav:after {
    clear: both; }
  #nav .sf-menu {
    position: relative;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    display: inline-block; }
    @media (max-width: 992px) {
      #nav .sf-menu {
        text-align: center;
        padding-right: 0;
        white-space: normal; } }
    #nav .sf-menu > li {
      margin: 0;
      padding: 0;
      position: relative;
      display: inline-block;
      font-size: 0;
      line-height: 0; }
      @media (max-width: 1600px) {
        #nav .sf-menu > li {
          padding-left: 8px;
          padding-right: 8px; } }
      @media (max-width: 1329px) {
        #nav .sf-menu > li {
          padding-left: 6px;
          padding-right: 6px; } }
      @media (max-width: 992px) {
        #nav .sf-menu > li {
          padding-left: 0px;
          padding-right: 0px;
          padding-top: 2px;
          padding-bottom: 2px; } }
      #nav .sf-menu > li > a {
        font-size: 18px;
        line-height: normal;
        color: #fff;
        font-weight: 400;
        font-family: "Marcellus", serif;
        text-decoration: none;
        display: inline-block;
        margin: 0;
        padding: 5px 20px 7px;
        transition: .2s ease-in-out;
        text-transform: capitalize;
        border-radius: 23.5px; }
        @media (max-width: 1329px) {
          #nav .sf-menu > li > a {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px; } }
        @media (max-width: 992px) {
          #nav .sf-menu > li > a {
            padding: 5px 7px;
            font-size: 13px; } }
      #nav .sf-menu > li.sfHover > a, #nav .sf-menu > li:hover > a {
        color: #000000;
        background-color: #fcc51c;
        border-radius: 23.5px; }
        #nav .sf-menu > li.sfHover > a:after, #nav .sf-menu > li:hover > a:after {
          color: #000 !important; }
      #nav .sf-menu > li > a.current-menu-item {
        color: #fff;
        background-color: transparent;
        border: 1px solid rgba(151, 151, 151, 0.4);
        padding: 4px 20px 6px; }
        #nav .sf-menu > li > a.current-menu-item:after {
          color: #fff !important; }
        #nav .sf-menu > li > a.current-menu-item:hover {
          opacity: .7; }
      #nav .sf-menu > li > a.sf-with-ul {
        display: flex;
        align-items: center; }
        #nav .sf-menu > li > a.sf-with-ul::after {
          content: "\f063";
          display: block;
          font-family: "FontAwesome";
          font-size: 8px;
          color: #d7d7d7;
          margin-left: 6px;
          font-weight: 400;
          margin-left: 8px;
          margin-top: 2px; }
          #nav .sf-menu > li > a.sf-with-ul::after:hover {
            color: #000; }
          @media (max-width: 1329px) {
            #nav .sf-menu > li > a.sf-with-ul::after {
              display: none; } }
    #nav .sf-menu li + li {
      padding-left: 15px; }
      @media (max-width: 1329px) {
        #nav .sf-menu li + li {
          padding-left: 5px; } }
      @media (max-width: 992px) {
        #nav .sf-menu li + li {
          background: none; } }
    #nav .sf-menu .dd_menu {
      position: absolute;
      background: #2e2827;
      min-width: 217px;
      display: none;
      text-align: left;
      border: 0;
      padding: 17px 20px;
      margin-top: 4px;
      border-radius: 14px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
      #nav .sf-menu .dd_menu li {
        display: block;
        position: relative;
        padding-left: 0; }
        #nav .sf-menu .dd_menu li a {
          font-size: 14px;
          line-height: normal;
          color: #f2ede9;
          font-weight: 400;
          text-decoration: none;
          border-left: none;
          padding: 4px 15px;
          display: block;
          letter-spacing: 0.5px;
          font-family: "Marcellus", serif; }
          #nav .sf-menu .dd_menu li a:hover {
            color: #fcc51c; }

/**     H E A D E R  **/
header.site-header {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 115; }
  header.site-header > img {
    width: 100%; }
  header.site-header #wrapper {
    width: 100%;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 20px;
    transition: all .3s; }
    @media (max-width: 1329px) {
      header.site-header #wrapper {
        padding: 15px 20px; } }
    @media (max-width: 767px) {
      header.site-header #wrapper {
        height: 51px; } }
    header.site-header #wrapper a.btn {
      float: right; }
      @media (max-width: 992px) {
        header.site-header #wrapper a.btn {
          margin-top: 0px; } }
      @media (max-width: 767px) {
        header.site-header #wrapper a.btn {
          display: none;
          position: fixed;
          z-index: 1000;
          font-size: 0;
          width: 30px;
          height: 30px;
          padding: 0;
          background: none;
          border: none;
          right: 10px;
          margin-top: 0;
          top: 20px; }
          header.site-header #wrapper a.btn::before {
            content: '';
            font-size: 30px;
            color: #f5a623;
            font-family: "FontAwesome"; } }
    @media (max-width: 767px) {
      header.site-header #wrapper .facebook {
        display: none; } }
  header.site-header .logo {
    position: absolute;
    top: 23px;
    left: 44px;
    transition: all .3s; }
    @media (max-width: 1329px) {
      header.site-header .logo {
        width: auto; } }
    @media (max-width: 992px) {
      header.site-header .logo {
        top: 10px;
        left: 15px; } }
    @media (max-width: 767px) {
      header.site-header .logo {
        float: none;
        width: 83px;
        display: block;
        text-align: center;
        position: fixed;
        padding-top: 0;
        z-index: 1000;
        left: 50%;
        top: 3px;
        transform: translateX(-50%); } }
    header.site-header .logo a {
      text-decoration: none;
      display: block; }
      header.site-header .logo a img {
        float: left;
        padding-right: 10px;
        transition: all .3s;
        width: 150px; }
        @media (max-width: 1329px) {
          header.site-header .logo a img {
            width: 110px; } }
        @media (max-width: 992px) {
          header.site-header .logo a img {
            width: 75px; } }
        @media (max-width: 767px) {
          header.site-header .logo a img {
            width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            float: none;
            padding-right: 0; } }
      header.site-header .logo a p {
        font-size: 30px;
        line-height: 1.2;
        color: #e4ddbb;
        font-weight: 400;
        font-family: "Marcellus", serif;
        white-space: nowrap;
        padding-top: 20px; }
        @media (max-width: 1329px) {
          header.site-header .logo a p {
            font-size: 24px;
            padding-top: 24px; } }
        @media (max-width: 992px) {
          header.site-header .logo a p {
            font-size: 16px;
            padding-top: 8px; } }
        @media (max-width: 767px) {
          header.site-header .logo a p {
            padding-top: 0; } }
  header.site-header .phone {
    float: right;
    margin: 0;
    font-family: "Oswald", sans-serif;
    position: relative; }
    @media (max-width: 767px) {
      header.site-header .phone {
        position: fixed;
        z-index: 210;
        float: none;
        right: 23px;
        top: 8px; } }
    header.site-header .phone span {
      font-size: 13px;
      line-height: 1.2;
      color: #f5e8a7;
      font-weight: 300;
      display: block;
      padding: 0;
      text-transform: capitalize;
      font-family: "Oswald", sans-serif;
      letter-spacing: 0.8px; }
      @media (max-width: 767px) {
        header.site-header .phone span {
          display: none; } }
    header.site-header .phone a.tel {
      font-size: 22px;
      line-height: 1;
      color: #fff;
      font-weight: 400;
      font-family: "Oswald", sans-serif;
      display: block;
      padding: 0 22px;
      border-radius: 23.5px;
      border: solid 1px rgba(151, 151, 151, 0.4);
      margin-right: 13px;
      margin-left: 26px;
      line-height: 47px; }
      @media (max-width: 1329px) {
        header.site-header .phone a.tel {
          font-size: 18px;
          line-height: 36px;
          margin-left: 15px; } }
      @media (max-width: 992px) {
        header.site-header .phone a.tel {
          font-size: 16px;
          line-height: 32px;
          padding: 0 10px; } }
      @media (max-width: 767px) {
        header.site-header .phone a.tel {
          font-size: 0;
          padding: 0;
          margin: 0;
          line-height: 0;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          text-align: center; } }
      header.site-header .phone a.tel::before {
        content: "\f095";
        color: #edbb21;
        font-size: 20px;
        display: inline;
        font-family: "FontAwesome";
        z-index: 1000;
        margin-right: 13px; }
        @media (max-width: 767px) {
          header.site-header .phone a.tel::before {
            line-height: 35px;
            margin-right: 0; } }

header.site-header.sticky-header #wrapper {
  background: #000;
  padding: 14px 20px;
  transition: all .3s; }

header.site-header.sticky-header .logo {
  top: 13px;
  transition: all .3s; }
  @media (max-width: 992px) {
    header.site-header.sticky-header .logo {
      top: 8px; } }
  header.site-header.sticky-header .logo img {
    width: 95px;
    transition: all .3s; }
    @media (max-width: 992px) {
      header.site-header.sticky-header .logo img {
        width: 70px; } }

/* Sub pages instead of slider */
.top-block {
  padding: 164px 0 74px;
  background: url("../img/top-block-bg.jpg") no-repeat top;
  background-size: 100%; }
  @media (max-width: 1329px) {
    .top-block {
      background-size: cover; } }
  @media (max-width: 992px) {
    .top-block {
      background-size: contain;
      background-position-y: 40%; } }
  @media (max-width: 767px) {
    .top-block {
      background-size: cover;
      background-position-x: left;
      padding-top: 140px; } }
  .top-block .container {
    position: relative; }
  .top-block h1 {
    color: #fff;
    font-size: 50px;
    font-family: "Oswald", sans-serif;
    line-height: 74px;
    text-transform: uppercase;
    position: absolute;
    bottom: -20px;
    left: 15px;
    margin: 0;
    z-index: 5; }
    @media (max-width: 1329px) {
      .top-block h1 {
        font-size: 44px; } }
    @media (max-width: 992px) {
      .top-block h1 {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .top-block h1 {
        line-height: 42px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center; } }
    @media (max-width: 479px) {
      .top-block h1 {
        font-size: 30px;
        line-height: 32px; } }
  .top-block .top-block-text {
    background: url("../img/top-block-text-bg.png") no-repeat top;
    background-size: cover;
    max-width: 469px;
    padding: 31px 57px 0 47px;
    float: right;
    margin-right: -20px; }
    @media (max-width: 1329px) {
      .top-block .top-block-text {
        background-size: contain;
        margin-right: 0; } }
    @media (max-width: 992px) {
      .top-block .top-block-text {
        padding-left: 75px; } }
    @media (max-width: 767px) {
      .top-block .top-block-text {
        float: none;
        margin: 0 auto;
        padding: 15px 20px;
        max-width: 345px; } }
    .top-block .top-block-text h2 {
      font-size: 50px;
      line-height: 52px;
      color: #fff;
      font-family: "Oswald", sans-serif;
      letter-spacing: 2.7px;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 0;
      margin-bottom: 10px; }
      @media (max-width: 1329px) {
        .top-block .top-block-text h2 {
          font-size: 44px;
          line-height: 46px; } }
      @media (max-width: 992px) {
        .top-block .top-block-text h2 {
          font-size: 40px;
          line-height: 42px; } }
      @media (max-width: 479px) {
        .top-block .top-block-text h2 {
          font-size: 36px;
          line-height: 24px; } }
      .top-block .top-block-text h2 sub {
        font-size: 24px;
        bottom: 0; }
    .top-block .top-block-text h4 {
      font-size: 24px;
      color: #f2ede9;
      max-width: 397px;
      line-height: normal;
      letter-spacing: normal;
      display: inline-block;
      margin-top: 0;
      margin-bottom: 20px; }
      @media (max-width: 1329px) {
        .top-block .top-block-text h4 {
          font-size: 22px; } }
      @media (max-width: 992px) {
        .top-block .top-block-text h4 {
          font-size: 20px;
          max-width: 300px; } }
      @media (max-width: 767px) {
        .top-block .top-block-text h4 {
          margin: 0; } }
    .top-block .top-block-text a.btn {
      float: right; }
      @media (max-width: 767px) {
        .top-block .top-block-text a.btn {
          margin: 20px 0; } }
  .top-block:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 97px;
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    opacity: .9;
    z-index: 2;
    margin-top: -23px; }

/**     C O N T E N T          **/
section.content {
  position: relative; }
  section.content h1 {
    font-size: 50px;
    line-height: normal;
    color: #fff;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    margin: 0;
    padding: 0 0 15px 0; }
  section.content h2 {
    font-size: 40px;
    line-height: normal;
    color: #28180b;
    font-weight: 400;
    font-family: "Marcellus", serif;
    margin: 0;
    padding: 0 0 15px 0; }
    @media (max-width: 1329px) {
      section.content h2 {
        font-size: 36px; } }
    @media (max-width: 992px) {
      section.content h2 {
        font-size: 28px; } }
    @media (max-width: 767px) {
      section.content h2 {
        font-size: 30px; } }
  section.content h3 {
    font-size: 30px;
    line-height: normal;
    color: #7c4a1b;
    font-weight: 400;
    font-family: "Marcellus", serif;
    margin: 0;
    padding: 0 0 15px 0;
    /* @include break(custom) {
			font-size: 26px;
		} */ }
    @media (max-width: 1329px) {
      section.content h3 {
        font-size: 26px; } }
    @media (max-width: 992px) {
      section.content h3 {
        font-size: 22px; } }
    @media (max-width: 767px) {
      section.content h3 {
        font-size: 24px; } }
  section.content h4 {
    font-size: 22px;
    line-height: normal;
    color: #28180b;
    font-weight: 400;
    font-family: "Marcellus", serif;
    margin: 0;
    padding: 0 0 15px 0;
    /* @include break(custom) {
			font-size: 22px;
		} */ }
    @media (max-width: 992px) {
      section.content h4 {
        font-size: 16px; } }
  section.content h5 {
    font-size: 40px;
    line-height: normal;
    color: #000;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    margin: 0;
    padding: 0 0 15px 0;
    /* @include break(custom) {
			font-size: 34px;
		} */ }
    @media (max-width: 992px) {
      section.content h5 {
        font-size: 16px; } }
  section.content h6 {
    font-size: 20px;
    line-height: normal;
    color: #28180b;
    font-weight: 700;
    font-family: "PT Serif", serif;
    margin: 0 0 4px 0;
    padding: 0 0 15px 0;
    /* @include break(custom) {
			font-size: 16px;
		} */ }
    @media (max-width: 992px) {
      section.content h6 {
        font-size: 14px; } }
  section.content p {
    font-size: 18px;
    line-height: normal;
    color: #28180b;
    font-weight: 400;
    font-family: "PT Serif", serif;
    text-align: justify;
    opacity: .9; }
    section.content p a {
      color: #a43719; }
  section.content .bg-after-slider {
    padding: 53px 0 132px;
    background: url("../img/map-bg.jpg") top #000 no-repeat;
    background-size: 100%; }
    @media (max-width: 1600px) {
      section.content .bg-after-slider {
        background-size: auto; } }
    @media (max-width: 1329px) {
      section.content .bg-after-slider {
        background-position-y: -30%; } }
    @media (max-width: 992px) {
      section.content .bg-after-slider {
        background-position-y: -40%;
        padding-bottom: 60px; } }
    @media (max-width: 767px) {
      section.content .bg-after-slider {
        padding: 28px 0 40px; } }
    section.content .bg-after-slider h2 {
      margin-top: 287px;
      color: #ffedb7;
      padding-bottom: 46px; }
      @media (max-width: 1329px) {
        section.content .bg-after-slider h2 {
          margin-top: 200px; } }
      @media (max-width: 992px) {
        section.content .bg-after-slider h2 {
          margin-top: 170px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider h2 {
          margin-top: 53px;
          padding-bottom: 10px; } }
      @media (max-width: 479px) {
        section.content .bg-after-slider h2 {
          max-width: 320px; } }
    section.content .bg-after-slider h3 {
      color: #28180b;
      max-width: 312px;
      margin-top: 59px; }
      @media (max-width: 992px) {
        section.content .bg-after-slider h3 {
          margin-top: 20px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider h3 {
          margin-top: 0;
          max-width: max-content;
          text-align: center;
          padding-bottom: 9px; } }
      @media (max-width: 479px) {
        section.content .bg-after-slider h3 {
          max-width: 312px;
          margin: 0 auto; } }
    section.content .bg-after-slider h6 {
      font-size: 43px;
      font-family: "Oswald", sans-serif;
      padding: 0;
      margin: 0;
      color: #000;
      display: flex;
      align-items: baseline;
      line-height: 45px; }
      section.content .bg-after-slider h6 span {
        color: #a30014;
        margin-left: 10px; }
      @media (max-width: 1329px) {
        section.content .bg-after-slider h6 {
          font-size: 36px;
          line-height: 38px; } }
      @media (max-width: 992px) {
        section.content .bg-after-slider h6 {
          font-size: 32px;
          line-height: 34px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider h6 {
          color: #f2ede9;
          font-size: 30px;
          line-height: 32px;
          width: fit-content; } }
    section.content .bg-after-slider h6 + h6 {
      font-size: 53px;
      line-height: 53px;
      margin-bottom: 20px; }
      @media (max-width: 1329px) {
        section.content .bg-after-slider h6 + h6 {
          font-size: 46px;
          line-height: 46px; } }
      @media (max-width: 992px) {
        section.content .bg-after-slider h6 + h6 {
          font-size: 37px;
          line-height: 37px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider h6 + h6 {
          font-size: 36px;
          line-height: 36px; } }
    @media (max-width: 767px) {
      section.content .bg-after-slider .text-before-button {
        margin: 0 auto;
        width: fit-content; } }
    section.content .bg-after-slider span.number {
      font-size: 89px; }
      @media (max-width: 1329px) {
        section.content .bg-after-slider span.number {
          font-size: 80px; } }
      @media (max-width: 992px) {
        section.content .bg-after-slider span.number {
          font-size: 64px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider span.number {
          font-size: 62px; } }
    @media (max-width: 767px) {
      section.content .bg-after-slider .text-with-button {
        margin-top: -30px;
        text-align: center; } }
    section.content .bg-after-slider a.btn {
      margin-left: 70px; }
      @media (max-width: 767px) {
        section.content .bg-after-slider a.btn {
          margin-left: 0; } }
    section.content .bg-after-slider p {
      color: #f2ede9;
      font-size: 17px; }
      @media (max-width: 992px) {
        section.content .bg-after-slider p {
          font-size: 16px; } }
      section.content .bg-after-slider p span {
        font-size: 24px;
        line-height: 20px; }
        @media (max-width: 992px) {
          section.content .bg-after-slider p span {
            font-size: 22px; } }
    section.content .bg-after-slider .pc-section {
      padding-left: 100px; }
      @media (max-width: 992px) {
        section.content .bg-after-slider .pc-section {
          padding-left: 25px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider .pc-section {
          padding-left: 0; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider .pc-section img {
          margin: 33px auto 0;
          display: block; } }
      section.content .bg-after-slider .pc-section p {
        color: #a58669;
        font-family: "Marcellus", serif;
        font-size: 22px;
        text-align: left;
        padding-top: 15px; }
        @media (max-width: 992px) {
          section.content .bg-after-slider .pc-section p {
            font-size: 20px; } }
        @media (max-width: 767px) {
          section.content .bg-after-slider .pc-section p {
            text-align: center; } }
        @media (max-width: 479px) {
          section.content .bg-after-slider .pc-section p {
            max-width: 280px;
            margin: 0 auto; } }
    section.content .bg-after-slider .our-client-text {
      font-size: 25px;
      text-align: right;
      color: #a58669;
      font-family: "Marcellus", serif; }
      @media (max-width: 992px) {
        section.content .bg-after-slider .our-client-text {
          font-size: 20px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider .our-client-text {
          font-size: 22px;
          text-align: center; } }
      @media (max-width: 479px) {
        section.content .bg-after-slider .our-client-text {
          max-width: 330px;
          margin: 30px auto 0; } }
    section.content .bg-after-slider .our-client-wrapper {
      padding-left: 80px; }
      @media (max-width: 992px) {
        section.content .bg-after-slider .our-client-wrapper {
          padding-left: 25px; } }
      @media (max-width: 767px) {
        section.content .bg-after-slider .our-client-wrapper {
          padding-left: 0; } }
      section.content .bg-after-slider .our-client-wrapper a.btn {
        margin: 0;
        margin-top: 24px; }
      section.content .bg-after-slider .our-client-wrapper p a {
        color: #fcc51c; }
    section.content .bg-after-slider .our-client-row {
      margin-top: 60px; }
      @media (max-width: 767px) {
        section.content .bg-after-slider .our-client-row {
          margin-top: 20px; } }
  section.content .section-with-list-of-projects {
    padding-top: 90px;
    padding-bottom: 100px;
    background: url("../img/section-list-bg.jpg") no-repeat #25201d;
    background-size: cover; }
    @media (max-width: 767px) {
      section.content .section-with-list-of-projects {
        padding-top: 40px;
        padding-bottom: 30px; } }
    section.content .section-with-list-of-projects h2 {
      color: #ffedb7;
      padding-bottom: 50px; }
      @media (max-width: 767px) {
        section.content .section-with-list-of-projects h2 {
          text-align: center;
          padding-bottom: 28px; } }
      @media (max-width: 479px) {
        section.content .section-with-list-of-projects h2 {
          max-width: 307px;
          margin: 0 auto; } }
    section.content .section-with-list-of-projects #show-more {
      font-size: 12px;
      color: #ffedb7;
      text-transform: uppercase;
      text-align: center;
      margin: 40px auto 0;
      background: transparent;
      text-decoration: underline; }
      section.content .section-with-list-of-projects #show-more:active, section.content .section-with-list-of-projects #show-more:focus {
        border: none; }
      section.content .section-with-list-of-projects #show-more:hover {
        text-decoration: none; }
  section.content .section-american-flag-bg {
    background: url("../img/american-flag.jpg") #000 no-repeat top;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 490px; }
    @media (max-width: 767px) {
      section.content .section-american-flag-bg {
        padding-top: 52px;
        background-size: auto;
        background-position-x: left;
        padding-bottom: 30px; } }
    section.content .section-american-flag-bg h2 {
      color: #ffedb7;
      padding-bottom: 50px; }
      @media (max-width: 767px) {
        section.content .section-american-flag-bg h2 {
          text-align: center;
          padding-bottom: 15px; } }
      @media (max-width: 479px) {
        section.content .section-american-flag-bg h2 {
          max-width: 292px;
          margin: 0 auto; } }
    section.content .section-american-flag-bg ul.services li {
      position: relative;
      border: solid 1px #3d3d3d;
      margin-bottom: 10px;
      min-height: 88px; }
      section.content .section-american-flag-bg ul.services li:hover {
        border-color: #ffedb7; }
        section.content .section-american-flag-bg ul.services li:hover a {
          color: #fcc51c; }
      section.content .section-american-flag-bg ul.services li a {
        text-decoration: none;
        color: #f2ede9;
        font-size: 20px;
        font-family: "Marcellus", serif;
        line-height: normal;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 19px 32px; }
        section.content .section-american-flag-bg ul.services li a:hover {
          color: #fcc51c; }
        @media (max-width: 767px) {
          section.content .section-american-flag-bg ul.services li a {
            text-align: center; } }
    section.content .section-american-flag-bg .last li:last-child {
      padding-right: 7px; }
      section.content .section-american-flag-bg .last li:last-child a {
        font-size: 15px;
        line-height: normal;
        letter-spacing: normal; }
  .services-page section.content .one-item {
    background-color: #fff;
    padding: 5px 5px 20px;
    margin-bottom: 15px;
    transition: .2s all; }
    @media (max-width: 767px) {
      .services-page section.content .one-item {
        max-width: 500px;
        margin: 0 auto 15px; } }
    .services-page section.content .one-item img {
      width: 100%; }
    .services-page section.content .one-item h4 {
      text-decoration: none;
      padding: 15px 0 0;
      color: #28180b; }
    .services-page section.content .one-item a {
      text-decoration: none;
      color: #a58669;
      font-size: 14px;
      font-family: "PT Serif", serif; }
      @media (max-width: 992px) {
        .services-page section.content .one-item a {
          font-size: 11px; } }
      @media (max-width: 767px) {
        .services-page section.content .one-item a {
          font-size: 14px; } }
    .services-page section.content .one-item:hover {
      background-color: #f2ede9;
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5); }
      .services-page section.content .one-item:hover h4, .services-page section.content .one-item:hover a {
        color: #7c4a1b; }
  .services-page section.content .second-row {
    margin-top: 50px; }
  .services-page section.content .section-map-bg {
    padding: 67px 0 80px;
    background: url("../img/map-sub-page-bg.jpg") no-repeat top #fcf7ea;
    background-size: 100%; }
    .services-page section.content .section-map-bg h2 {
      text-align: center;
      padding-bottom: 50px; }
    .services-page section.content .section-map-bg h3 {
      padding-top: 40px; }
  .services-page section.content .section-map-bg.no-padding-bottom {
    padding-bottom: 0; }
    @media (max-width: 1329px) {
      .services-page section.content .section-map-bg.no-padding-bottom p + p {
        padding-bottom: 30px; } }
    @media (max-width: 1329px) {
      .services-page section.content .section-map-bg.no-padding-bottom img {
        position: absolute;
        bottom: 0; } }
    @media (max-width: 767px) {
      .services-page section.content .section-map-bg.no-padding-bottom img {
        display: block;
        margin: 0 auto;
        position: relative; } }
  .services-page section.content .section-map-bg.logo-design {
    padding-bottom: 55px; }
    .services-page section.content .section-map-bg.logo-design h3 {
      padding-top: 0; }
    .services-page section.content .section-map-bg.logo-design h4 {
      padding-top: 15px;
      padding-bottom: 12px; }
  .services-page section.content .section-contacts-parallax {
    padding: 60px 0 68px;
    background: url("../img/section-list-bg.jpg") no-repeat center top fixed;
    background-size: cover; }
    .services-page section.content .section-contacts-parallax h2, .services-page section.content .section-contacts-parallax h3 {
      color: #ffedb7; }
      .services-page section.content .section-contacts-parallax h2 a, .services-page section.content .section-contacts-parallax h3 a {
        color: #ffedb7; }
    .services-page section.content .section-contacts-parallax h2 {
      padding-bottom: 27px; }
    .services-page section.content .section-contacts-parallax a + a {
      margin-left: 22px; }
      @media (max-width: 479px) {
        .services-page section.content .section-contacts-parallax a + a {
          margin-left: 0; } }
    @media (max-width: 479px) {
      .services-page section.content .section-contacts-parallax a.btn {
        display: block;
        max-width: 196px;
        margin: 20px auto 0; } }
  .services-page section.content .section-after-parallax {
    padding: 65px 0;
    background: url("../img/after-parallax-bg.jpg") no-repeat top #fcf7ea;
    background-size: 100%; }
    .services-page section.content .section-after-parallax h2 {
      padding-bottom: 50px;
      text-align: center; }
    .services-page section.content .section-after-parallax h3 {
      padding-bottom: 15px; }
    .services-page section.content .section-after-parallax a img:hover {
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5); }
  @media (max-width: 767px) {
    .services-page section.content .section-after-parallax.trophy-page .second-row {
      margin: 0; } }
  @media (max-width: 767px) {
    .services-page section.content .section-after-parallax.trophy-page a img {
      margin-bottom: 10px; } }
  .contacts-page section.content .section-map-bg {
    padding-bottom: 134px; }
    .contacts-page section.content .section-map-bg h2 {
      text-align: left; }
      @media (max-width: 992px) {
        .contacts-page section.content .section-map-bg h2 {
          text-align: center; } }
    .contacts-page section.content .section-map-bg h4 {
      letter-spacing: 0;
      padding: 0 0 10px; }
    .contacts-page section.content .section-map-bg a.tel {
      color: #000;
      font-size: 40px;
      line-height: 40px;
      font-family: "Oswald", sans-serif; }
    .contacts-page section.content .section-map-bg a.mail-info {
      font-size: 30px;
      line-height: 30px;
      color: #7c4a1b; }
      @media (max-width: 1329px) {
        .contacts-page section.content .section-map-bg a.mail-info {
          font-size: 24px; } }
      @media (max-width: 992px) {
        .contacts-page section.content .section-map-bg a.mail-info {
          display: block;
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .contacts-page section.content .section-map-bg a.mail-info {
          font-size: 22px; } }
      @media (max-width: 479px) {
        .contacts-page section.content .section-map-bg a.mail-info {
          font-size: 20px; } }
    .contacts-page section.content .section-map-bg a.contants-logo img {
      width: 357px; }
    @media (max-width: 992px) {
      .contacts-page section.content .section-map-bg a.contants-logo {
        display: block;
        text-align: center;
        margin-bottom: 30px; } }
    .contacts-page section.content .section-map-bg a.tel + h4 {
      margin-top: 22px; }
    .contacts-page section.content .section-map-bg div.nivo-slice img {
      padding: 0; }
    @media (max-width: 992px) {
      .contacts-page section.content .section-map-bg form {
        margin: 0 auto; } }

.hand-border {
  height: 1px;
  background: #7c4a1b;
  margin: 0 0 50px; }

@media (max-width: 1329px) {
  .border-bottom-row .mobile-in-hand {
    position: absolute;
    bottom: 0;
    max-width: 290px; } }

@media (max-width: 992px) {
  .border-bottom-row .mobile-in-hand {
    max-width: 220px; } }

@media (max-width: 767px) {
  .border-bottom-row .mobile-in-hand {
    max-width: none;
    position: relative;
    margin: 0 auto;
    display: block; } }

@media (max-width: 479px) {
  .border-bottom-row .mobile-in-hand {
    max-width: 250px; } }

ul.projects-list a {
  text-decoration: none;
  color: #f2ede9;
  font-size: 15px;
  font-family: "PT Serif", serif;
  opacity: .9;
  margin-left: -13px;
  transition: .2s all;
  display: flex;
  align-items: center; }
  ul.projects-list a:before {
    content: "\f105";
    display: inline-block;
    font-family: 'FontAwesome';
    font-size: 14px;
    color: #ffedb7;
    padding-right: 8px;
    opacity: 0;
    transition: .2s all;
    position: relative; }
    @media (max-width: 767px) {
      ul.projects-list a:before {
        padding-right: 3px; } }
  ul.projects-list a:hover {
    color: #fcc51c;
    margin-left: 0; }
    ul.projects-list a:hover:before {
      opacity: 1; }
  @media (max-width: 767px) {
    ul.projects-list a {
      font-size: 12px; } }

ul.projects-list-li li {
  text-decoration: none;
  color: #f2ede9;
  font-size: 15px;
  font-family: "PT Serif", serif;
  opacity: .9;
  margin-left: -13px;
  transition: .2s all;
  display: flex;
  align-items: center; }
  ul.projects-list-li li:before {
    content: "\f105";
    display: inline-block;
    font-family: 'FontAwesome';
    font-size: 14px;
    color: #ffedb7;
    padding-right: 8px;
    opacity: 0;
    transition: .2s all;
    position: relative; }
    @media (max-width: 767px) {
      ul.projects-list-li li:before {
        padding-right: 3px; } }
  ul.projects-list-li li:hover {
    color: #fcc51c;
    margin-left: 0; }
    ul.projects-list-li li:hover:before {
      opacity: 1; }
  @media (max-width: 992px) {
    ul.projects-list-li li {
      font-size: 12px; } }

@media (max-width: 767px) {
  img.mobile-center {
    margin: 20px auto 0;
    display: block; } }

/* S I D E B A R   */
.sidebar {
  padding-right: 20px; }

/* G A L L E R Y */
a.gallery {
  display: block;
  border: 1px #7e5d47 solid;
  padding: 4px;
  background: #ffe49a;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  width: 48%;
  float: left;
  margin-right: 2%; }
  a.gallery::before {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    background: rgba(255, 255, 255, 0.2); }
  a.gallery::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    font-family: 'FontAwesome';
    text-align: center;
    color: #fff;
    font-size: 30px;
    transform: translate(-50%, -50%); }
  a.gallery:hover::before, a.gallery:hover::after {
    opacity: 1; }
  a.gallery img {
    width: 100%; }
  a.gallery p {
    text-align: center;
    padding-top: 15px;
    color: #a43719;
    font-size: 20px; }

a.facebook {
  font-size: 0;
  float: right;
  margin-top: 25px;
  display: block;
  border-radius: 4px;
  overflow: hidden; }
  @media (max-width: 992px) {
    a.facebook {
      margin-top: 0; } }
  a.facebook img {
    display: block;
    padding: 0;
    margin: 0; }

/**     F O O T E R        F O O T E R         F O O T E R         **/
.footer-container {
  padding-left: 150px; }
  @media (max-width: 767px) {
    .footer-container {
      padding-left: 15px;
      width: 310px; } }

footer.site-footer {
  padding: 0 0 13px 0;
  overflow: hidden;
  margin-top: -369px; }
  @media (max-width: 767px) {
    footer.site-footer {
      margin-top: 0;
      background-color: #000; } }
  footer.site-footer .footer-container {
    position: relative; }
    footer.site-footer .footer-container .for-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 30px;
      border-bottom: 2px solid #464646; }
      @media (max-width: 767px) {
        footer.site-footer .footer-container .for-flex {
          flex-direction: column;
          align-items: baseline;
          border-bottom: none; } }
      footer.site-footer .footer-container .for-flex p {
        margin-bottom: 0; }
  footer.site-footer .footer-phone {
    float: left;
    position: relative; }
    footer.site-footer .footer-phone p {
      font-size: 18px;
      line-height: 1.4;
      color: #ffedb7;
      font-weight: 400;
      font-family: "Marcellus", serif;
      float: left;
      min-width: 240px;
      display: flex;
      align-items: baseline;
      line-height: 18px; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-phone p {
          font-size: 14px; } }
      @media (max-width: 992px) {
        footer.site-footer .footer-phone p {
          font-size: 12px;
          min-width: 160px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-phone p {
          font-size: 18px;
          display: flex;
          flex-direction: column; } }
      footer.site-footer .footer-phone p a {
        font-size: 30px;
        line-height: 1.4;
        color: #f2ede9;
        font-weight: 400;
        font-family: "Oswald", sans-serif;
        margin-left: 10px;
        line-height: 30px; }
        @media (max-width: 1329px) {
          footer.site-footer .footer-phone p a {
            font-size: 24px; } }
        @media (max-width: 992px) {
          footer.site-footer .footer-phone p a {
            font-size: 16px; } }
        @media (max-width: 767px) {
          footer.site-footer .footer-phone p a {
            font-size: 50px;
            line-height: 64px;
            margin-left: 0; } }
  footer.site-footer .footer-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 135px; }
    @media (max-width: 992px) {
      footer.site-footer .footer-logo {
        left: 20px;
        width: 80px; } }
    @media (max-width: 767px) {
      footer.site-footer .footer-logo {
        float: none;
        width: 100%;
        display: block;
        text-align: center;
        position: relative;
        margin-right: 0;
        margin-bottom: 15px; } }
    footer.site-footer .footer-logo a {
      max-width: 190px;
      text-align: center;
      text-decoration: none;
      display: block; }
      @media (max-width: 992px) {
        footer.site-footer .footer-logo a {
          max-width: 160px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-logo a {
          max-width: fit-content;
          width: auto;
          text-align: center;
          margin-left: auto;
          margin-right: auto; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-logo a img {
          width: 200px; } }
  footer.site-footer ul.footer-social {
    display: flex;
    align-self: flex-end; }
    @media (max-width: 767px) {
      footer.site-footer ul.footer-social {
        position: relative;
        padding-top: 35px;
        display: inline-block;
        align-self: auto; } }
    footer.site-footer ul.footer-social li {
      display: inline;
      padding-right: 17px; }
      @media (max-width: 992px) {
        footer.site-footer ul.footer-social li {
          padding-right: 10px; } }
      @media (max-width: 767px) {
        footer.site-footer ul.footer-social li {
          padding-right: 17px; } }
      footer.site-footer ul.footer-social li a {
        color: #f2ede9;
        font-size: 30px;
        line-height: 30px; }
        @media (max-width: 992px) {
          footer.site-footer ul.footer-social li a {
            font-size: 27px;
            line-height: 27px; } }
        @media (max-width: 767px) {
          footer.site-footer ul.footer-social li a {
            font-size: 30px;
            line-height: 30px; } }
        footer.site-footer ul.footer-social li a:hover {
          color: #fcc51c; }
    footer.site-footer ul.footer-social li:first-child {
      color: #ffedb7;
      font-size: 18px;
      font-family: "Marcellus", serif;
      padding-right: 22px;
      align-self: flex-end; }
      @media (max-width: 992px) {
        footer.site-footer ul.footer-social li:first-child {
          font-size: 15px; } }
      @media (max-width: 767px) {
        footer.site-footer ul.footer-social li:first-child {
          font-size: 18px;
          position: absolute;
          top: 0; } }
  footer.site-footer .bottom-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 33px; }
    @media (max-width: 767px) {
      footer.site-footer .bottom-footer {
        padding-top: 0; } }
    footer.site-footer .bottom-footer ul {
      min-width: 150px;
      align-self: baseline; }
      @media (max-width: 767px) {
        footer.site-footer .bottom-footer ul {
          display: none; } }
      footer.site-footer .bottom-footer ul li a {
        color: #94908d;
        font-size: 14px; }
        footer.site-footer .bottom-footer ul li a:hover {
          color: #fcc51c; }
  .contacts-page footer.site-footer {
    margin-top: 0;
    padding-top: 70px; }

@media (max-width: 767px) {
  #mobile-bottom-fixed-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    border-radius: 0;
    z-index: 110; } }

#mobile-bottom-fixed-btn:active, #mobile-bottom-fixed-btn:focus {
  top: auto; }

/*-------------------    Copyright  --------------------*/
.copyright {
  padding: 35px 0px 18px;
  overflow: hidden;
  position: relative; }
  @media (max-width: 767px) {
    .copyright {
      background: #000;
      padding-bottom: 112px; } }
  .copyright p {
    font-size: 18px;
    line-height: normal;
    color: #484646;
    font-weight: 300;
    font-family: "Oswald", sans-serif;
    letter-spacing: 0.3px; }
    @media (max-width: 1329px) {
      .copyright p {
        font-size: 14px; } }
    @media (max-width: 767px) {
      .copyright p {
        width: 100%;
        text-align: center;
        font-size: 18px; } }

/* C O N D I T I O N A L    L O G I C */
#ajax-contact-form .div_select > p {
  border: 4px #979797 solid;
  padding: 10px;
  display: none; }
  #ajax-contact-form .div_select > p strong {
    display: block;
    margin-left: 5px;
    padding-bottom: 10px; }
  #ajax-contact-form .div_select > p b {
    display: block;
    background: #cb1325;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 6px;
    margin-top: 4px;
    font-weight: 400;
    display: block;
    display: none; }
  #ajax-contact-form .div_select > p input[type="checkbox"] {
    background: #fff;
    border: 1px #979797 solid;
    width: 15px;
    height: 15px;
    margin-right: 12px;
    float: left;
    margin-bottom: 6px; }
  #ajax-contact-form .div_select > p label {
    font-size: 16px;
    line-height: 1.2;
    color: #24130d;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
    margin-left: 5px;
    margin-bottom: 0; }

/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/
#ajax-contact-form {
  text-align: left;
  background-color: rgba(242, 237, 233, 0.75);
  max-width: 715px;
  border-radius: 20px;
  border: 4px solid #a58669;
  padding: 35px 50px 30px; }
  @media (max-width: 767px) {
    #ajax-contact-form {
      padding: 20px 15px 30px; } }
  #ajax-contact-form h2 {
    padding: 0 0 22px; }
  #ajax-contact-form p {
    margin-bottom: 0;
    text-align: left;
    font-size: 16px; }
    #ajax-contact-form p.middle {
      line-height: 3; }
    #ajax-contact-form p.select {
      position: relative; }
  #ajax-contact-form span {
    font-size: 16px;
    font-family: "PT Serif", serif;
    color: #28180b; }
  #ajax-contact-form .signature {
    width: 240px; }
  #ajax-contact-form input[type=checkbox] {
    display: none; }
  #ajax-contact-form .checkbox-wrapper label {
    font-weight: 400; }
    #ajax-contact-form .checkbox-wrapper label:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 1px solid #d8d8d8;
      background-color: #fff;
      margin-right: 14px; }
      @media (max-width: 767px) {
        #ajax-contact-form .checkbox-wrapper label:before {
          margin-right: 5px; } }
  #ajax-contact-form input[type=checkbox]:checked + label:before {
    content: "\f00c";
    font-family: "FontAwesome";
    line-height: 18px;
    font-size: 16px;
    font-weight: 300;
    color: #a58669; }

#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
  padding: 4px 12px 6px;
  background: #fff;
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.5em;
  color: #000;
  font-weight: 300;
  margin: 4px 0 12px;
  border: solid 1px #d8d8d8;
  font-family: "PT Serif", serif; }

#ajax-contact-form input[type=checkbox], #ajax-contact-form input[type=radio] {
  width: auto;
  margin-top: 0;
  margin-bottom: 13px;
  background-color: #fff;
  margin-right: 14px; }

#ajax-contact-form select {
  font-size: 14px;
  padding-left: 8px;
  height: 36px;
  color: #000;
  font-family: "PT Serif", serif; }

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #979797;
  background: #fff;
  color: #000;
  outline: none;
  font-family: "PT Serif", serif; }

#ajax-contact-form textarea {
  height: 132px;
  max-width: 100%; }

#ajax-contact-form *::-webkit-input-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

#ajax-contact-form *::-moz-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

#ajax-contact-form *:focus::-webkit-input-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

#ajax-contact-form *:focus::-moz-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder {
  color: #a58669;
  font-family: "PT Serif", serif;
  font-size: 14px; }

#button_submit,
#fields .submit {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 18px;
  line-height: normal;
  color: #fff;
  font-weight: 400;
  margin: 0;
  font-family: "Marcellus", serif;
  text-decoration: none;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 23.5px;
  text-align: center;
  text-transform: capitalize;
  text-shadow: none;
  background: #a30014;
  transition: .2s all;
  width: auto;
  float: right;
  display: inline; }
  #button_submit:after,
  #fields .submit:after {
    content: "\f18e";
    font-family: "FontAwesome";
    display: inline;
    font-size: 20px;
    color: #fff;
    margin-left: 11px;
    line-height: 46px;
    transition: .2s all; }
  @media (max-width: 1329px) {
    #button_submit,
    #fields .submit {
      font-size: 14px; } }
  @media (max-width: 992px) {
    #button_submit,
    #fields .submit {
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      float: none; } }
  @media (max-width: 767px) {
    #button_submit,
    #fields .submit {
      line-height: 20px; } }
  @media (max-width: 479px) {
    #button_submit,
    #fields .submit {
      font-size: 11px; } }
  #button_submit:hover,
  #fields .submit:hover {
    background: #cd0c24;
    color: #fff;
    transition: .2s all; }
    #button_submit:hover:after,
    #fields .submit:hover:after {
      content: "\f0a9";
      transition: .2s all; }

#button_submit:active, #button_submit:focus {
  color: #fff;
  top: 4px;
  background-color: #b10d21;
  transition: .2s all; }
  #button_submit:active:after, #button_submit:focus:after {
    content: "\f0a9"; }

.notification_ok {
  padding-bottom: 15px;
  color: #000;
  line-height: 1.5em;
  font-size: 18px;
  text-align: left;
  padding-top: 15px; }

.notification_error {
  color: #e54423;
  clear: both;
  text-align: left !important;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 700; }

#note {
  clear: both; }

#rc-imgelect, .g-recaptcha {
  width: 250px;
  transform: scale(0.82);
  transform-origin: 0 0;
  float: left;
  margin-right: 15px; }
  @media (max-width: 1600px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.74);
      transform-origin: 0 0; } }
  @media (max-width: 1329px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.6);
      transform-origin: 0 0; } }
  @media (max-width: 992px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.8);
      transform-origin: 0 0; } }
  @media (max-width: 479px) {
    #rc-imgelect, .g-recaptcha {
      float: none; } }

img {
  height: auto;
  max-width: 100%; }

.clearfix {
  clear: both; }

#ajax-contact-form label.error {
  color: #f00;
  font-size: 12px;
  margin-top: -8px;
  display: inline-block;
  font-weight: 400;
  clear: both; }

@media (max-width: 992px) {
  .mobile-none {
    display: none; } }

div#button_submit {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  letter-spacing: 0; }

input.error {
  background-color: #ffd5d5 !important;
  border: 1px solid #ff0000 !important; }

label.error {
  color: red; }

.contact-form-head {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }
  .contact-form-head p {
    color: #d0021b !important;
    font-size: 14px; }

.form-fields-wrapper {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 479px) {
    .form-fields-wrapper {
      flex-direction: column; } }
  .form-fields-wrapper .form-section {
    width: 48%; }
    @media (max-width: 479px) {
      .form-fields-wrapper .form-section {
        width: 100%; } }

.checkbox-position-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 15px; }
  @media (max-width: 479px) {
    .checkbox-position-wrapper {
      flex-direction: column; } }
  .checkbox-position-wrapper em {
    font-size: 15px;
    font-family: "PT Serif", serif;
    color: #28180b; }
    @media (max-width: 767px) {
      .checkbox-position-wrapper em {
        font-size: 13px; } }

/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/
.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: #332315;
  display: none;
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 51px; }
  @media (max-width: 767px) {
    .slicknav_menu {
      display: block; } }
  .slicknav_menu .phone-mobile {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    font-weight: 300;
    float: left;
    padding-left: 10px;
    padding-top: 6px; }
    .slicknav_menu .phone-mobile a {
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      font-weight: 300; }
    @media (max-width: 479px) {
      .slicknav_menu .phone-mobile span {
        display: none; } }
  .slicknav_menu * {
    box-sizing: border-box; }
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 3px #000; }
  .slicknav_menu .slicknav_icon-bar {
    background-color: #f5841f; }

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden;
  background: #332315;
  z-index: 9999;
  top: -1px;
  position: relative; }
  .slicknav_nav .slicknav_row:click, .slicknav_nav .slicknav_row:active, .slicknav_nav .slicknav_row:focus {
    color: #ffe49a;
    outline: none; }
  .slicknav_nav a {
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    border-bottom: 1px rgba(255, 255, 255, 0.2) dashed;
    padding-bottom: 0;
    text-transform: uppercase;
    padding-left: 20px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    outline: none !important; }
    .slicknav_nav a:hover {
      color: #ffe49a; }
    @media (max-width: 767px) {
      .slicknav_nav a + i {
        display: none; } }
  .slicknav_nav a.btn,
  .slicknav_nav a.btn:active,
  .slicknav_nav a.btn:focus {
    background: none;
    border: 0;
    border-top: 1px rgba(255, 255, 255, 0.2) dashed;
    border-bottom: 1px rgba(255, 255, 255, 0.2) dashed;
    text-transform: capitalize;
    border-radius: 0;
    padding-top: 0; }
  .slicknav_nav .current-menu-item a {
    color: #fff; }
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; }
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; }
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; }

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; }

.slicknav_btn {
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border-radius: 2px;
  width: 32px;
  height: 52px;
  background: url("../img/mobile-button.png") 50% 0px no-repeat;
  background-size: 52px auto;
  float: left;
  margin: 0;
  padding: 0;
  top: 5px;
  left: 20px;
  position: relative;
  outline: none !important;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease; }
  .slicknav_btn span {
    display: none; }
  .slicknav_btn.slicknav_open {
    background-position: 50% -50px; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 10px 0 10px 0;
    margin: 0; }
    .slicknav_nav ul li a {
      text-transform: capitalize;
      line-height: 24px;
      color: #fff;
      border: none;
      font-size: 15px;
      font-weight: 400;
      padding-left: 30px;
      display: block; }
      .slicknav_nav ul li a:hover {
        color: #ffe49a; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    color: #ffe49a;
    font-family: 'FontAwesome';
    float: right;
    margin-right: 20px; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline;
      border-bottom: none; }
      .slicknav_nav .slicknav_item a:click, .slicknav_nav .slicknav_item a:active, .slicknav_nav .slicknav_item a:focus {
        color: #ffe49a;
        outline: none; }
  .slicknav_nav .slicknav_row {
    display: block; }
    .slicknav_nav .slicknav_row:click, .slicknav_nav .slicknav_row:active, .slicknav_nav .slicknav_row:focus {
      color: #fff;
      outline: none; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

/**     O T H E R       **/
a {
  color: #00215c;
  text-decoration: underline; }

a:focus {
  color: #00215c;
  text-decoration: none; }

a:hover {
  text-decoration: none;
  color: #000; }

.center {
  text-align: center; }

.scrolloff {
  pointer-events: none; }

.map {
  position: relative;
  padding-bottom: 50%;
  padding-top: 30px;
  height: 0;
  background: #ccc; }
  .map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0; }

ul.list {
  padding: 0 0 10px 10px;
  margin: 0;
  overflow: hidden; }
  ul.list li {
    padding-left: 25px;
    position: relative;
    padding-bottom: 14px;
    font-size: 18px;
    line-height: normal;
    color: 400;
    font-weight: normal;
    color: rgba(40, 24, 11, 0.9);
    font-family: "PT Serif", serif; }
    @media (max-width: 767px) {
      ul.list li {
        font-size: 16px; } }
    ul.list li::after {
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 9px;
      border-radius: 50%;
      display: block;
      background: #a58669;
      content: ''; }

.right {
  float: right; }
  @media (max-width: 767px) {
    .right {
      float: none; } }

.right_20 {
  margin-right: 25px; }

.paddcontent {
  padding-bottom: 20px; }
  @media (max-width: 767px) {
    .paddcontent {
      padding-bottom: 10px; } }

.smallpadd {
  padding-bottom: 10px; }

/* Buttons */
.btn {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 18px;
  line-height: normal;
  color: #fff;
  font-weight: 400;
  margin: 0;
  font-family: "Marcellus", serif;
  text-decoration: none;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 0 16px;
  border-radius: 23.5px;
  text-align: center;
  text-transform: capitalize;
  text-shadow: none;
  background: #a30014;
  transition: .2s all; }
  .btn:after {
    content: "\f18e";
    font-family: "FontAwesome";
    display: inline;
    font-size: 20px;
    color: #fff;
    margin-left: 11px;
    line-height: 46px;
    transition: .2s all; }
    @media (max-width: 1329px) {
      .btn:after {
        line-height: 35px;
        font-size: 18px; } }
    @media (max-width: 992px) {
      .btn:after {
        font-size: 16px;
        line-height: 32px;
        margin-left: 4px; } }
    @media (max-width: 767px) {
      .btn:after {
        font-size: 20px;
        line-height: 46px;
        margin-left: 10px; } }
  @media (max-width: 1329px) {
    .btn {
      font-size: 16px; } }
  @media (max-width: 992px) {
    .btn {
      font-size: 14px;
      padding: 0 8px; } }
  @media (max-width: 767px) {
    .btn {
      font-size: 18px;
      padding: 0 16px; } }
  .btn:hover {
    background: #cd0c24;
    color: #fff;
    transition: .2s all; }
    .btn:hover:after {
      content: "\f0a9";
      transition: .2s all; }

.btn:active, .btn:focus {
  color: #fff;
  top: 4px;
  background-color: #b10d21;
  transition: .2s all; }
  .btn:active:after, .btn:focus:after {
    content: "\f0a9"; }

.btn-yellow {
  color: #000;
  background-color: #fcc51c;
  text-transform: none; }
  .btn-yellow:after {
    color: #000; }
  .btn-yellow:hover {
    color: #000;
    background-color: #ffe144; }
  .btn-yellow:active, .btn-yellow:focus {
    color: #000;
    background-color: #eed033;
    top: 4px; }

hr {
  margin: 0 0 5px;
  padding: 0;
  background: #d7d7d7;
  height: 1px;
  width: 100%;
  border: 0; }

.red {
  color: #ba0003; }

a[href*="tel"] {
  text-decoration: none;
  cursor: default; }

::selection {
  background: #ededed !important;
  color: #000000; }

::-moz-selection {
  background: #ededed !important;
  color: #000000; }

#cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 #gallery_cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 {
  width: 40%;
  float: left;
  background: #ffedb7;
  padding: 6px;
  box-shadow: 0 9px 14px 0 rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 5%;
  min-height: 2em; }
  @media (max-width: 767px) {
    #cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 #gallery_cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 {
      float: none;
      width: 80%;
      margin: 0 auto;
      top: 15%;
      position: relative; } }

#cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled {
  padding-top: 0;
  width: 55% !important;
  float: right;
  padding: 30px 15px;
  background-color: #fff; }
  @media (max-width: 992px) {
    #cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled {
      width: 50% !important; } }
  @media (max-width: 767px) {
    #cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled {
      float: none;
      width: 95% !important; } }
  @media (max-width: 992px) {
    #cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled img {
      width: 80px !important; } }

#cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-directionNav a {
  text-decoration: none; }
  #cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-directionNav a:hover:before {
    opacity: .7; }

div.nivo-slice img, div.nivo-box img {
  padding: 6px; }

#cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled a {
  border: 1px solid transparent;
  transition: .4s all; }
  #cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled a:hover {
    border: 1px solid #ffedb7;
    box-shadow: 0 9px 14px 0 rgba(0, 0, 0, 0.25);
    transition: .4s all; }

.wow {
  visibility: hidden; }

#facebook-iframe {
  width: 100%;
  max-width: 520px;
  height: 800px;
  display: block;
  margin: 0 auto; }

div.fb-page {
  height: 600px; }
  div.fb-page iframe {
    height: 600px; }
