@import "_variables";

/* Fonts */
/*
font-family: 'Oswald', sans-serif;
font-family: 'Marcellus', serif;
font-family: 'PT Serif', serif;
*/

/* Media Screens */

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
	@include fz(18px, 1.4, #292b2e, 400);
	font-family: $Marcellus;
	background: #0e0e0e;
	@include break(medium) {
		font-size: 15px;
	}
	@include break(mobile) {
	}

	@include break(x-small) {
		min-width: 320px;
		font-size: 14px;
	}
}
.site-container {
	position: relative;
	z-index: 9;
	width: 1600px;
	margin: 0 auto;
	body & {
		@include break(large) {
			width: 1600px;
		}
	}
	@include break(desktop) {
		width: 100%;
	}
	@include break(medium) {
		width: 100%;
	}	
	@include break(neutral) {
	}
	@include break(mobile) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	@include break(x-small) {
		width: 100%;
		min-width: 320px;
	}
}
.container {
	@include break(desktop) {
		width: 1270px;
	}
}

/**   nav menu  **/

#nav {
	@include break(mobile) {display: none;}
	@include clearfix();
	z-index: 10;
	position: relative;
	text-align: center;
	display: inline;	

	.sf-menu {
		position: relative;
		font-size: 0;
		line-height: 0;
		white-space: nowrap;	
		text-align: center;	
		display: inline-block;

		@include break(neutral) {
			text-align: center;		
			padding-right: 0;
			white-space: normal;
		}

		& > li {
			margin: 0;
			padding: 0;
			position: relative;
			display: inline-block;
			font-size: 0;
			line-height: 0;
			@include break(custom) {
				padding-left: 8px;
				padding-right: 8px;
			}
			@include break(medium) {
				padding-left: 6px;
				padding-right: 6px;
			}
			@include break(neutral) {
				padding-left: 0px;
				padding-right: 0px;
				padding-top: 2px;
				padding-bottom: 2px;
			}

			& > a {
				@include fz(18px, normal, #fff, 400);
				font-family: $Marcellus;
				text-decoration: none;
				display: inline-block;
				margin: 0 ;
				padding: 5px 20px 7px;
				transition: .2s ease-in-out;
				text-transform: capitalize;		
				border-radius: 23.5px;
						
				@include break(medium) {
					font-size: 14px;
					padding-left: 10px;
					padding-right: 10px;
				}
				@include break(neutral) {
					padding: 5px 7px;
					font-size: 13px;
				}
			}

			&.sfHover,
			&:hover {
				& > a {
					color: #000000;	
					background-color: #fcc51c;
					border-radius: 23.5px;
					&:after {
						color: #000 !important;
					}
				}
			}
			& > a.current-menu-item {
				color: #fff;
				background-color: transparent;
				border: 1px solid rgba(151, 151, 151, .4);;	
				padding: 4px 20px 6px;
				&:after {
					color: #fff !important;
				}
				&:hover {
					opacity: .7;	
				}
			}
	
			& > a.sf-with-ul {
				display: flex;
				align-items: center;
				&::after {
					content:"\f063";
					display: block;
					font-family: "FontAwesome";
					font-size: 8px;
					color: #d7d7d7;
					margin-left: 6px;
					font-weight: 400;
					margin-left: 8px;
					margin-top: 2px;
					&:hover {
						color: #000;
					}
					@include break(medium) {
						display: none;						
					}
				}
			}	
		}
		li + li {			
			padding-left: 15px;
			@include break(medium) {
				padding-left: 5px;
			}
			@include break(neutral) {
				background: none;
			}
		}


		.dd_menu {
			position: absolute;
			background: #2e2827;
			min-width: 217px;
			display: none;
			text-align: left;
			border: 0;
			padding: 17px 20px; 
			margin-top: 4px;
			border-radius: 14px;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			li {
				display: block;				
				position: relative;
				padding-left: 0;
				a {
					@include fz(14px, normal, #f2ede9, 400);								
					text-decoration: none;
					border-left: none;
					padding: 4px 15px;
					display: block;
					letter-spacing: 0.5px;
					font-family: $Marcellus;
					&:hover {
						color: #fcc51c;
					}
				}
			}
		}
	}
}




/**     H E A D E R  **/
header.site-header {
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index:  115;
	& > img {
		width: 100%;		
	}

	#wrapper {
		width: 100%;
		padding: 0;
		position: relative;
		display: flex;
	    flex-direction: row;
	    justify-content: flex-end;
	    align-items: center;
	    padding: 24px 20px;
	    transition: all .3s;
	    @include break(medium) {
	    	padding: 15px 20px;
	    }
		@include break(neutral) {
		}
		@include break(mobile) {
			height: 51px;
		}

		a.btn {
	  		float: right;
			@include break(neutral) {
				margin-top: 0px;
			}
			@include break(mobile) {
				display: none;
				position: fixed;
				z-index: 1000;
				font-size: 0;
				width: 30px;
				height: 30px;
				padding: 0;
				background: none;
				border: none;
				right: 10px;
				margin-top: 0;
				top: 20px;
				&::before {
					content: '';
					font-size: 30px;
					color: #f5a623;
					font-family: "FontAwesome";
				}
			}
		}
		.facebook {
			@include break(mobile) {
				display: none;
			}			
		}
	}

	.logo {
		position: absolute;
		top: 23px;
		left: 44px;
		transition: all .3s;
		@include break(medium) {
			width: auto;
		}
		@include break(neutral) {
			top: 10px;
			left: 15px;
		}

		@include break(mobile) {
			float: none;
			width: 83px;
			display: block;
			text-align: center;
			position: fixed;
			padding-top: 0;
			z-index: 1000;
			left: 50%;
			top: 3px;
			transform: translateX(-50%);
		}
		a {
			text-decoration: none;
			display: block;			
			img {
				float: left;
				padding-right: 10px;
				transition: all .3s;
				width: 150px;
				@include break(medium) {
					width: 110px;
				}

				@include break(neutral) {
					width: 75px;
				}
				@include break(mobile) {
					width: 100%;
					display: block;
					margin-left: auto;
					margin-right: auto;
					float: none;
					padding-right: 0;
				}
			}
			p {
				@include fz(30px, 1.2, #e4ddbb, 400);								
				font-family: $Marcellus;
				white-space: nowrap;
				padding-top: 20px;
				@include break(medium) {
					font-size: 24px;
					padding-top:24px;
				}
				@include break(neutral) {
					font-size: 16px;
					padding-top: 8px;
				}
				@include break(mobile) {
					padding-top: 0;
				}

			}
		}
	}

	.phone {
		float: right;
		margin: 0;
		font-family: $Oswald;
		position: relative;
		@include break(medium) {
		}
		@include break(neutral) {
		}
		@include break(mobile) {
			position: fixed;
			z-index: 210;
			float: none;
			right: 23px;
			top: 8px;
		}

		span {
			@include fz(13px, 1.2, #f5e8a7, 300);						
			display: block;
			padding: 0;
			text-transform: capitalize;
			font-family: $Oswald;
			letter-spacing: 0.8px;
			@include break(mobile) {
				display: none;
			}

		}

		a.tel {
			@include fz(22px, 1, #fff, 400);		
			font-family: $Oswald;
			display: block;
			padding: 0 22px;
			border-radius: 23.5px;
  			border: solid 1px rgba(151, 151, 151, .4);
  			margin-right: 13px;
  			margin-left: 26px;
			line-height: 47px;


			@include break(medium) {
				font-size: 18px;
				line-height: 36px;
				margin-left: 15px;
			}
			@include break(neutral) {
				font-size: 16px;
				line-height: 32px;
				padding: 0 10px;
			}
			@include break(mobile) {
				font-size: 0;
				padding: 0;
				margin: 0;
				line-height: 0;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				text-align: center;
			}	
			&::before {
				
					content: "\f095";
					color: #edbb21;
					font-size: 20px;
					display: inline;
					font-family: "FontAwesome";
					z-index: 1000;
					margin-right: 13px;
					@include break(mobile) {
						line-height: 35px;
						margin-right: 0;
					}
				
			}

			@include break(x-small) {

			}	
		}
	}

}
header.site-header.sticky-header {
	#wrapper {
		background: #000;
		padding: 14px 20px;
		transition: all .3s;
	}
	.logo {
		top: 13px;
		transition: all .3s;
		@include break(neutral) {
			top: 8px;
		}

		img {
			width: 95px;
			transition: all .3s;
			@include break(neutral) {
				width: 70px;
			}
			
		}
		// @include break(medium) {
		// 	width: auto;
		// }
		// @include break(neutral) {
		// 	top: 10px;
		// 	left: 15px;
		// }

		// a {
		// 	text-decoration: none;
		// 	display: block;			
		// 	img {
		// 		float: left;
		// 		padding-right: 10px;
		// 		@include break(medium) {
		// 			width: 110px;
		// 		}

		// 		@include break(neutral) {
		// 			width: 75px;
		// 		}
		// 		@include break(mobile) {
		// 			width: 100%;
		// 			display: block;
		// 			margin-left: auto;
		// 			margin-right: auto;
		// 			float: none;
		// 			padding-right: 0;
		// 		}
		// 	}
			
		// }
	}
}



/* Sub pages instead of slider */

.top-block {
	padding: 164px 0 74px;
	background: url("../img/top-block-bg.jpg") no-repeat top;
	background-size: 100%;
	@include break(medium) {
		background-size: cover;
	}
	@include break(neutral) {
		background-size: contain;
		background-position-y: 40%;
	}
	@include break(mobile) {
		background-size: cover;
		background-position-x: left;
		padding-top: 140px;
	}
	.container {
		position: relative;
	}
	h1 {
		color: #fff;
		font-size: 50px;
		font-family: $Oswald;
		line-height: 74px;
		text-transform: uppercase;
		position: absolute;
		bottom: -20px;
		left: 15px;
		margin: 0;
		z-index: 5;
		@include break(medium) {
			font-size: 44px;
		}
		@include break(neutral) {
			font-size: 40px;
		}
		@include break(mobile) {
			line-height: 42px;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
			text-align: center;

		}
		@include break(x-small) {
			font-size: 30px;
			line-height: 32px;
		}
	}
	.top-block-text {
		background: url("../img/top-block-text-bg.png") no-repeat top;
		background-size: cover;
		max-width: 469px;
		padding: 31px 57px 0 47px;
		float: right;
		margin-right: -20px;
		@include break(medium) {
			background-size: contain;
			margin-right: 0;
		}
		@include break(neutral) {
			padding-left: 75px;

		}
		@include break(mobile) {
			float: none;
			margin: 0 auto;
			padding: 15px 20px;
			max-width: 345px;
		}
		h2 {
			font-size: 50px;
			line-height: 52px;
			color: #fff;
			font-family: $Oswald;
			letter-spacing: 2.7px;
			text-transform: uppercase;
			display: inline-block;
			margin-top: 0;
			margin-bottom: 10px;
			@include break(medium) {
				font-size: 44px;
				line-height: 46px;
			}
			@include break(neutral) {
				font-size: 40px;
				line-height: 42px;
			}
			@include break(x-small) {
				font-size: 36px;
				line-height: 24px;
			}
			sub {
				font-size: 24px;
				bottom: 0;
			}
		}
		h4 {
			font-size: 24px;
			color: #f2ede9;
			max-width: 397px;
			line-height: normal;
			letter-spacing: normal;
			display: inline-block;
			margin-top: 0;
			margin-bottom: 20px;
			@include break(medium) {
				font-size: 22px;
			}
			@include break(neutral) {
				font-size: 20px;
				max-width: 300px;
			}
			@include break(mobile) {
				margin: 0;

			}
		}
		a.btn {
			float: right;
			@include break(mobile) {
				margin: 20px 0;
			}
		}
	}
	&:after {
		content: "";
	    display: block;
	    width: 100%;
	    position: absolute;
	    height: 97px;
	    background-image: linear-gradient(to top,#000,rgba(0,0,0,0));
	    opacity: .9;
	    z-index: 2;
	    margin-top: -23px;
	}
}


/**     C O N T E N T          **/
section.content {
	position: relative;
	h1 {
		@include fz(50px, normal, #fff, 400);	
		font-family: $Oswald;
		margin: 0;
		padding: 0 0 15px 0;
		
		// @include break(neutral) {
		// 	font-size: 16px;
		// }
	}
	h2 {
		@include fz(40px, normal, #28180b, 400);	
		font-family: $Marcellus;
		margin: 0;
		padding: 0 0 15px 0;
		@include break(medium) {
			font-size: 36px;
		}

		@include break(neutral) {
			font-size: 28px;
		}
		@include break(mobile) {
			font-size: 30px;
		}
		@include break(x-small) {
		}

	}
	h3 {
		@include fz(30px, normal, #7c4a1b, 400);	
		font-family: $Marcellus;
		margin: 0;
		padding: 0 0 15px 0;
		/* @include break(custom) {
			font-size: 26px;
		} */
		@include break(medium) {
			font-size: 26px;
		}
		@include break(neutral) {
			font-size: 22px;
		}
		@include break(mobile) {
			font-size: 24px;
		}

	}	
	h4 {
		@include fz(22px, normal, #28180b, 400);	
		font-family: $Marcellus;
		margin: 0;
		padding: 0 0 15px 0;
		/* @include break(custom) {
			font-size: 22px;
		} */
		@include break(neutral) {
			font-size: 16px;
		}
	}
	h5 {
		@include fz(40px, normal, #000, 400);	
		font-family: $Oswald;
		margin: 0;
		padding: 0 0 15px 0;
		/* @include break(custom) {
			font-size: 34px;
		} */
		@include break(neutral) {
			font-size: 16px;
		}
	}	
	h6 {
		@include fz(20px, normal, #28180b, 700);	
		font-family: $Ptserif;
		margin: 0 0 4px 0;
		padding: 0 0 15px 0;
		/* @include break(custom) {
			font-size: 16px;
		} */
		@include break(neutral) {
			font-size: 14px;
		}

	}	
	p {
		@include fz(18px, normal, #28180b, 400);	
		font-family: $Ptserif;
		text-align: justify;
		opacity: .9;	
		a {
			color: #a43719;
		}
	}
	
	.bg-after-slider {
		padding: 53px 0 132px;
		background: url("../img/map-bg.jpg") top #000 no-repeat;
		background-size: 100%;
		@include break(custom) {
			background-size: auto;
		}
		@include break(medium) {
			background-position-y: -30%;
		}
 		@include break(neutral) {
			background-position-y: -40%;
			padding-bottom: 60px;
		}
		@include break(mobile) {
			padding: 28px 0 40px;
		}
		h2 {
			margin-top: 287px;
			color: #ffedb7;
			padding-bottom: 46px;
			@include break(medium) {
				margin-top: 200px;
			}
			@include break(neutral) {
				margin-top: 170px;
			}
			@include break(mobile) {
				margin-top: 53px;
				padding-bottom: 10px;
			}
			@include break(x-small) {
				max-width: 320px;
			}
		}
		h3 {
			color: #28180b;
			max-width: 312px;
			margin-top: 59px;
			@include break(neutral) {
				margin-top: 20px;
			}
			@include break(mobile) {
				margin-top: 0;
				max-width: max-content;
				text-align: center;
				padding-bottom: 9px;
			}
			@include break(x-small) {
				max-width: 312px;
				margin: 0 auto;
			}
		}
		h6 {
			font-size: 43px;
			font-family: $Oswald;
			padding: 0;
			margin: 0;
			color: #000;
			display: flex;
			align-items: baseline;
			line-height: 45px;	
			span {
				color: #a30014;
				margin-left: 10px;
			}
			@include break(medium) {
				font-size: 36px;
				line-height: 38px;
			}
			@include break(neutral) {
				font-size: 32px;
				line-height: 34px;
			}
			@include break(mobile) {
				color: #f2ede9;
				font-size: 30px;
				line-height: 32px;
				width: fit-content;
			}
		}
		h6 + h6 {
			font-size: 53px;
			line-height: 53px;
			margin-bottom: 20px;
			@include break(medium) {
				font-size: 46px;
				line-height: 46px;
			}
			@include break(neutral) {
				font-size: 37px;
				line-height: 37px;
			}
			@include break(mobile) {
				font-size: 36px;
				line-height: 36px;
			}
		}
		.text-before-button {
			@include break(mobile) {
				margin: 0 auto;
				width: fit-content;
			}
		}
		
		span.number {
			font-size: 89px;
			@include break(medium) {
				font-size: 80px;
			}
			@include break(neutral) {
				font-size: 64px;
			}
			@include break(mobile) {
				font-size: 62px;
			}
		}
		.text-with-button {
			@include break(mobile) {
				margin-top: -30px;
				text-align: center;
			}
		}
		a.btn {
			margin-left: 70px;
			@include break(mobile) {
				margin-left: 0;
			}
		}
		p {
			color: #f2ede9;
			font-size: 17px;
			@include break(neutral) {
				font-size: 16px;
			}
			span {
				font-size: 24px;
				line-height: 20px;
				@include break(neutral) {
					font-size: 22px;
				}
			}
		}
		.pc-section {
			padding-left: 100px;
			@include break(neutral) {
				padding-left: 25px;
			}
			@include break(mobile) {
				padding-left: 0;
			}
			img {
				@include break(mobile) {
					margin: 33px auto 0;
					display: block;
				}
			}
			p {
				color: #a58669;
				font-family: $Marcellus;
				font-size: 22px;
				text-align: left;
				padding-top: 15px;
				@include break(neutral) {
					font-size: 20px;
				}
				@include break(mobile) {
					text-align: center;
				}
				@include break(x-small) {
					max-width: 280px;
					margin: 0 auto;
				}
			}
		}
		.our-client-text {
			font-size: 25px;
			text-align: right;
			color: #a58669;
			font-family: $Marcellus;
			@include break(neutral) {
				font-size: 20px;
			}
			@include break(mobile) {
				font-size: 22px;
				text-align: center;
			}
			@include break(x-small) {
				max-width: 330px;
				margin: 30px auto 0;
			}
		}
		.our-client-wrapper {
			padding-left: 80px;
			@include break(neutral) {
				padding-left: 25px;
			}
			@include break(mobile) {
				padding-left: 0;
			}
			a.btn {
				margin: 0;
				margin-top: 24px;
			}
			p {
				a {
					color: #fcc51c;
				}
			}
		}
		.our-client-row {
			margin-top: 60px;
			@include break(mobile) {
				margin-top: 20px;
			}
		}


	}
	.section-with-list-of-projects {
		padding-top: 90px;
		padding-bottom: 100px;
		background: url("../img/section-list-bg.jpg") no-repeat #25201d;
		background-size: cover;
		@include break(mobile) {
			padding-top: 40px;
			padding-bottom: 30px;
		}
		h2 {
			color: #ffedb7;
			padding-bottom: 50px;
			@include break(mobile) {
				text-align: center;
				padding-bottom: 28px;
			}
			@include break(x-small) {
				max-width: 307px;
				margin: 0 auto;
			}
		}
		#show-more {
			font-size: 12px;
			color: #ffedb7;
			text-transform: uppercase;
			text-align: center;
			margin: 40px auto 0;
			background: transparent;
			text-decoration: underline;
			&:active, &:focus {
				border: none;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
	.section-american-flag-bg {
		background: url('../img/american-flag.jpg') #000 no-repeat top;
		background-size: cover;
		padding-top: 100px;
		padding-bottom: 490px;
		@include break(mobile) {
			padding-top: 52px;
			background-size: auto;
			background-position-x: left;
			padding-bottom: 30px;
		}
		h2 {
			color: #ffedb7;
			padding-bottom: 50px;
			@include break(mobile) {
				text-align: center;
				padding-bottom: 15px;
			}
			@include break(x-small) {
				max-width: 292px;
				margin: 0 auto;
			}
		}
		ul.services {
			li {
				position: relative;
				border: solid 1px #3d3d3d;
				margin-bottom: 10px;
				min-height: 88px;
				&:hover {
					border-color: #ffedb7;
					a {
						color: #fcc51c;
					}
				}
				a {
					text-decoration: none;
					color: #f2ede9;
					font-size: 20px;
					font-family: $Marcellus;
					line-height: normal;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 19px 32px;
					&:hover {
						color: #fcc51c;
					}
					@include break(mobile) {
						text-align: center;

					}
				}
			}
		}
		.last {
			li:last-child {
				padding-right: 7px;
				a {
					font-size: 15px;
					line-height: normal;
					letter-spacing: normal;
				}
			}
		}
	}
	
	.services-page & {
		.one-item {
				background-color: #fff;
				padding: 5px 5px 20px;
				margin-bottom: 15px;
				transition: .2s all;
				@include break(mobile) {
					max-width: 500px;
					margin: 0 auto 15px;
				}
				img {
					width: 100%;
				}
				h4 {
					text-decoration: none;
					padding: 15px 0 0;
					color: #28180b;
				}
				a {
					text-decoration: none;
					color: #a58669;
					font-size: 14px;
					font-family: $Ptserif;
					@include break(neutral) {
						font-size: 11px;
					}
					@include break(mobile) {
						font-size: 14px;
					}
				}
				&:hover {
					background-color: #f2ede9;
					box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
					h4, a {
						color: #7c4a1b;
					}
				}

			}
		.second-row {
			margin-top: 50px;
		}
		.section-map-bg {
			padding: 67px 0 80px;
			background: url('../img/map-sub-page-bg.jpg') no-repeat top #fcf7ea;
			background-size: 100%;
			h2 {
				text-align: center;
				padding-bottom: 50px;
			}
			h3 {
				padding-top: 40px;
			}
		}
		.section-map-bg.no-padding-bottom {
			padding-bottom: 0;
			p + p {
				@include break(medium) {
					padding-bottom: 30px;
				}
			}
			img {
				@include break(medium) {
					position: absolute;
					bottom: 0;
				}
				@include break(mobile) {
					display: block;
					margin: 0 auto;
					position: relative;
				}
			}
		}
		.section-map-bg.logo-design {
			padding-bottom: 55px;
			h3 {
				padding-top: 0;
			}
			h4 {
				padding-top: 15px;
				padding-bottom: 12px;
			}
		}
		.section-contacts-parallax {
			padding: 60px 0 68px;
			background: url('../img/section-list-bg.jpg') no-repeat center top fixed;
			background-size: cover;
			
			h2, h3 {
				color: #ffedb7;
				a {
					color: #ffedb7;
				}
			}
			h2 {
				padding-bottom: 27px;
			}
			a + a {
				margin-left: 22px;
				@include break(x-small) {
					margin-left: 0;
				}
			}
			a.btn {
				@include break(x-small) {
					display: block;
					max-width: 196px;
					margin: 20px auto 0;
				}
			}
		}
		.section-after-parallax	{
			padding: 65px 0;
			background: url("../img/after-parallax-bg.jpg") no-repeat top #fcf7ea;
			background-size: 100%;
			h2 {
				padding-bottom: 50px;
				text-align: center;
			}
			h3 {
				padding-bottom: 15px;
			}
			a {
				img {
					&:hover {
						box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
					}
				}
			}
		}
		.section-after-parallax.trophy-page {
			.second-row {
				@include break(mobile) {

					margin: 0;
				}
			}
			a {
				img {
					@include break(mobile) {
						margin-bottom: 10px;
					}
				}
			}
		}

	}
	.contacts-page & {
		.section-map-bg {
			padding-bottom: 134px;
			h2 {
				text-align: left;
				@include break(neutral) {
					text-align: center;
				}
			}
			h4 {
				letter-spacing: 0;
				padding: 0 0 10px;
			}
			a.tel {
				color: #000;
				font-size: 40px;
				line-height: 40px;
				font-family: $Oswald;
			}
			a.mail-info {
				font-size: 30px;
				line-height: 30px;
				color: #7c4a1b;
				@include break(medium) {
					font-size: 24px;
				}
				@include break(neutral) {
					display: block;
					margin: 0 auto;
				}
				@include break(mobile) {
					font-size: 22px;
				}
				@include break(x-small) {
					font-size: 20px;
				}
			}
			a.contants-logo {
				img {
					width: 357px;
					// margin-top: 57px;
				}
				@include break(neutral) {
					display: block;
					text-align: center;
					margin-bottom: 30px;
				}
			}
			a.tel + h4 {
				margin-top: 22px;
			}
			div.nivo-slice img {
				padding: 0;
			}
			form {
				@include break(neutral) {
					margin: 0 auto;
				}
			}
		}
	}
				
}
.hand-border {
	height: 1px;
	background: #7c4a1b;
	margin: 0 0 50px;
}
.border-bottom-row {
	@include break(medium) {
		.mobile-in-hand {
			position: absolute;
			bottom: 0;
			max-width: 290px;
		}
	}
	@include break(neutral) {
		.mobile-in-hand {
			max-width: 220px;
		}
	}
	@include break(mobile) {
		.mobile-in-hand {
			max-width: none;
			position: relative;
			margin: 0 auto;
			display: block;
		}
	}
	@include break(x-small) {
		.mobile-in-hand {
			max-width: 250px;
		}
	}
}
ul.projects-list {
	a {
		text-decoration: none;
		color: #f2ede9;
		font-size: 15px;
		font-family: $Ptserif;
		opacity: .9;
		margin-left: -13px;
		transition: .2s all;
		display: flex;
		align-items: center;
		&:before {
			content: "\f105";
			display: inline-block;
			font-family: 'FontAwesome';
			font-size: 14px;
			color: #ffedb7;
			padding-right: 8px;
			opacity: 0;
			transition: .2s all;
			position: relative;
			@include break(mobile) {
				padding-right: 3px;
			}
		}
		&:hover {
			color: #fcc51c;
			margin-left: 0;
			&:before {
				opacity: 1;
			}

		}
		@include break(mobile) {
			font-size: 12px;
		}
	}
}
ul.projects-list-li {
	li {
		text-decoration: none;
		color: #f2ede9;
		font-size: 15px;
		font-family: $Ptserif;
		opacity: .9;
		margin-left: -13px;
		transition: .2s all;
		display: flex;
		align-items: center;
		&:before {
			content: "\f105";
			display: inline-block;
			font-family: 'FontAwesome';
			font-size: 14px;
			color: #ffedb7;
			padding-right: 8px;
			opacity: 0;
			transition: .2s all;
			position: relative;
			@include break(mobile) {
				padding-right: 3px;
			}
		}
		&:hover {
			color: #fcc51c;
			margin-left: 0;
			&:before {
				opacity: 1;
			}

		}
		@include break(neutral) {
			font-size: 12px;
		}
	}
}
img.mobile-center {
	@include break(mobile) {
		margin: 20px auto 0;
		display: block;
	}
}


/* S I D E B A R   */
.sidebar {	padding-right: 20px;}



/* G A L L E R Y */
a.gallery {
	display: block;
	border: 1px #7e5d47 solid;
	padding: 4px;
	background: #ffe49a; 
	margin-bottom: 20px;
	margin-top: 10px;
	position: relative;	
	text-decoration: none;
	border-radius: 4px;
	width:48%;
	float: left;
	margin-right: 2%;
	&::before {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@include link_trans();
		background: rgba(255, 255, 255, .2);

	}
	&::after {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 50px;
		height: 50px;
		@include link_trans();
		font-family: 'FontAwesome';
		text-align: center;
		color: #fff;
		font-size: 30px;
		transform: translate(-50%, -50%);
	}
	&:hover {
		&::before,
		&::after {
			opacity: 1;
		}
	}
	img {
		width: 100%;
	}
	p {
		text-align: center;
		padding-top: 15px;
		color: #a43719;
		font-size: 20px;
	}
}



a.facebook {
	font-size: 0;
	float: right;
	margin-top: 25px;			
	display: block;
	border-radius: 4px;
	overflow: hidden;
	@include break(neutral) {
		margin-top: 0;
	}

	img {
		display: block;
		padding: 0;
		margin: 0;
	}
}


/**     F O O T E R        F O O T E R         F O O T E R         **/
.footer-container {
	padding-left: 150px;
	@include break(mobile) {
		padding-left: 15px;
		width: 310px;
	}
}
footer.site-footer {
	padding: 0 0 13px 0;
	overflow: hidden;
	margin-top: -369px;
	@include break(mobile) {
		margin-top: 0;
		background-color: #000;
	}
	.footer-container {
		position: relative;
		.for-flex {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			padding-bottom: 30px;
			border-bottom: 2px solid #464646;
			@include break(mobile) {
				flex-direction: column;
				align-items: baseline;
				border-bottom: none;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
	
	.footer-phone {
		float: left;
		position: relative;

		p {
			@include fz(18px, 1.4, #ffedb7, 400);						
			font-family: $Marcellus;
			float: left;
			min-width: 240px;
			display: flex;
			align-items: baseline;
			line-height: 18px;
			@include break(medium) {
				font-size: 14px;
			}	
			@include break(neutral) {
				font-size: 12px;
				min-width: 160px;
			}
			@include break(mobile) {
				font-size: 18px;
				display: flex;
				flex-direction: column;
			}
			a {
				@include fz(30px, 1.4, #f2ede9, 400);
				font-family: $Oswald;
				margin-left: 10px;
				line-height: 30px;						
				@include break(medium) {
					font-size: 24px;
				}	
				@include break(neutral) {
					font-size: 16px;
				}	
				@include break(mobile) {
					font-size: 50px;
					line-height: 64px;
					margin-left: 0;
				}

			}
		}
	
	}	

	.footer-logo {
		position: absolute;
		left: 0;
		top: 0;
		width: 135px;
		@include break(neutral) {
			left: 20px;
			width: 80px;
		}
		@include break(mobile) {
			float: none;
			width: 100%;			
			display: block;
			text-align: center;
			position: relative;
			margin-right: 0;
			margin-bottom: 15px;
		}
		a {
			max-width: 190px;
			text-align: center;
			text-decoration: none;
			display: block;			
			@include break(neutral) {
				max-width: 160px;
			}	
			@include break(mobile) {
				max-width: fit-content;
				width: auto;
				text-align: center;
				margin-left: auto;
				margin-right: auto;
			}
			img {
				@include break(mobile) {
					width: 200px;
				}
			}
		}		
	}
	ul.footer-social {
		display: flex;
		align-self: flex-end;
		@include break(mobile) {
			position: relative;
			padding-top: 35px;
			display: inline-block;
			align-self: auto;
		}
		li {
			display: inline;
			padding-right: 17px;
			@include break(neutral) {
				padding-right: 10px;
			}
			@include break(mobile) {
				padding-right: 17px;
			}
			a {
				color: #f2ede9;
				font-size: 30px;
				line-height: 30px;
				@include break(neutral) {
					font-size: 27px;
					line-height: 27px;

				}
				@include break(mobile) {
					font-size: 30px;
					line-height: 30px;
				}
				&:hover {
					color: #fcc51c;
				}
			}
		}
		li:first-child {
			color: #ffedb7;
			font-size: 18px;
			font-family: $Marcellus;
			padding-right: 22px;
			align-self: flex-end;
			@include break(neutral) {
				font-size: 15px;
			}
			@include break(mobile) {
				font-size: 18px;
				position: absolute;
				top: 0;
			}
		}
	}
	.bottom-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-top: 33px;
		@include break(mobile) {
			padding-top: 0;
		}
		ul {
			min-width: 150px;
			align-self: baseline;
			@include break(mobile) {
				display: none;
			}
			li {
				a {
					color: #94908d;
					font-size: 14px;
					&:hover {
						color: #fcc51c;
					}
				}
			}
		}
	}

	.contacts-page & {
		margin-top: 0;
		padding-top: 70px;
	}

}
#mobile-bottom-fixed-btn {
	@include break(mobile) {
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 0;
		right: 0;
		border-radius: 0;
		z-index: 110;
	}
	&:active, &:focus {
		top: auto;
	}
}


/*-------------------    Copyright  --------------------*/
.copyright {
	padding: 35px 0px 18px;
	overflow: hidden;
	position: relative;
	@include break(mobile) {
		background: #000;
		padding-bottom: 112px;
	}
	p {
		@include fz(18px, normal, #484646, 300);			
		font-family: $Oswald;
		letter-spacing: 0.3px;
		@include break(medium) {
			font-size: 14px;
		}			
		@include break(mobile) {
			width: 100%;
			text-align: center;
			font-size: 18px;
		}
	}
		
}	

/* C O N D I T I O N A L    L O G I C */
#ajax-contact-form {
	.div_select {
		& > p {
			border: 4px #979797 solid;
			padding: 10px;
			display: none;
			strong {
				display: block;
				margin-left: 5px;
				padding-bottom: 10px;
			}	
			b{
				display: block;
				background: #cb1325;
				color: #fff;
				font-size: 16px;
				text-align: center;
				padding-top: 5px;
				padding-bottom: 6px;
				margin-top: 4px;
				font-weight: 400;
				display: block;
				display: none;
			}
			input[type="checkbox"]	{
				background: #fff;
				border: 1px #979797 solid;
				width: 15px;
				height: 15px;
				margin-right: 12px;
				float: left;
				margin-bottom: 6px;
			}
			label {
				@include fz(16px, 1.2, #24130d, 400);			
				overflow: hidden;
				width: 100%;
				margin-left: 5px;
				margin-bottom: 0;
			}
		}
	}	
}

/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/

#ajax-contact-form {
	text-align: left;
	background-color: rgba(242, 237, 233, 0.75);
	max-width: 715px;
	border-radius: 20px;
	border: 4px solid #a58669;
	padding: 35px 50px 30px;
	@include break(mobile) {
		padding: 20px 15px 30px;
	}
	h2 {
		padding: 0 0 22px;
	}
	p {
		margin-bottom: 0;
		text-align: left;
		font-size: 16px;
		&.middle {
			line-height: 3;
		}
		&.select {
			position: relative;

		}
	}
	span {
		font-size: 16px;
		font-family: $Ptserif;
		color: #28180b;
	}
	.signature {
		width: 240px;
	}
	input[type=checkbox] {
		display: none;
	}
	.checkbox-wrapper label {
		font-weight: 400;
		&:before {
			content: "";
			display: inline-block;
			width: 18px;
			height: 18px;
			border-radius: 3px;
			border: 1px solid #d8d8d8;
			background-color: #fff;
			margin-right: 14px;
			@include break(mobile) {
				margin-right: 5px;
			}
		}
	}
	input[type=checkbox]:checked + label {
		&:before {			
			content: "\f00c";
			font-family: "FontAwesome";
			line-height: 18px;
			font-size: 16px;
			font-weight: 300;
			color: #a58669;
		}
	}
}
#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
	padding: 4px 12px 6px;
	background: #fff;
	width: 100%;
	border-radius: 3px;
 	@include fz(14px, 1.5em, #000, 300);
	margin: 4px 0 12px;
    border: solid 1px #d8d8d8;
    font-family: $Ptserif;
}
#ajax-contact-form input[type=checkbox], #ajax-contact-form input[type=radio] {
	width: auto;
	margin-top: 0;
	margin-bottom: 13px;
	background-color: #fff;
	margin-right: 14px;
}
#ajax-contact-form select{
	font-size: 14px;
	padding-left: 8px;
	height: 36px;
	color: #000;
	font-family: $Ptserif;
}

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #979797;
  background: #fff;
	color: #000;
	outline: none;
	font-family: $Ptserif;
}
#ajax-contact-form textarea {	height: 132px; max-width: 100%; }
#ajax-contact-form *::-webkit-input-placeholder {color:#a58669; font-family: $Ptserif; font-size: 14px;}
#ajax-contact-form *::-moz-placeholder          {color:#a58669; font-family: $Ptserif; font-size: 14px;}/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder           {color:#a58669; font-family: $Ptserif; font-size: 14px;}/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder      {color:#a58669; font-family: $Ptserif; font-size: 14px;}

#ajax-contact-form *:focus::-webkit-input-placeholder {color:#a58669; font-family: $Ptserif; font-size: 14px;}
#ajax-contact-form *:focus::-moz-placeholder          {color:#a58669; font-family: $Ptserif; font-size: 14px;}/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder           {color:#a58669; font-family: $Ptserif; font-size: 14px;}/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder      {color:#a58669; font-family: $Ptserif; font-size: 14px;}


#button_submit,
#fields .submit {
	@include link_trans();
	@include fz(18px, normal, #fff, 400);
	margin: 0;
	font-family: $Marcellus;
	text-decoration: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 12px 25px;
	border-radius: 23.5px;
	text-align: center;
	text-transform: capitalize;
	text-shadow:none;	
	background: #a30014;
	transition: .2s all;
	width: auto;
	float: right;
	display: inline;
	&:after {
		content: "\f18e";
		font-family: "FontAwesome";
		display: inline;
		font-size: 20px;
		color: #fff;
		margin-left: 11px;
		line-height: 46px;
		transition: .2s all;
	}
	@include break(medium) {
		font-size: 14px;
	}
	@include break(neutral) {
		max-width: 240px;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	@include break(mobile) {
		line-height: 20px;
	}
	@include break(x-small) {
		font-size: 11px;
	}
	&:hover {
		background: #cd0c24;
		color: #fff;
		transition: .2s all;

		&:after {
			content: "\f0a9";
			transition: .2s all;
		}
	}	
}
#button_submit:active, #button_submit:focus {
	color: #fff;
	top: 4px;
	background-color: #b10d21;
	transition: .2s all;
	&:after {
		content: "\f0a9";
	}
}


.notification_ok {
	padding-bottom: 15px;
	color: #000;
	line-height: 1.5em;
	font-size: 18px;
	text-align: left;
	padding-top: 15px;
}

.notification_error {
	color: #e54423;
	clear: both;
	text-align: left!important;
	font-size: 12px;
	line-height: 1.5em;
	font-weight: 700;
}
#note {
	clear: both;
}
#rc-imgelect, .g-recaptcha {
  width: 250px;
  transform:scale(0.82);
  transform-origin:0 0;
  float: left;
  margin-right: 15px;
	@include break(custom){
	  transform:scale(0.74);
	  transform-origin:0 0;
	}
	@include break(medium){
	  transform:scale(0.60);
	  transform-origin:0 0;
	}
	@include break(neutral){
	  transform:scale(.8);
	  transform-origin:0 0;
	}
	@include break(x-small){
		float: none;
	}

}
img {		height: auto;		max-width:100%;	}
.clearfix {	clear:both;}

#ajax-contact-form label.error {
	color: #f00;
	font-size: 12px;
	margin-top: -8px;
	display: inline-block;
	font-weight: 400;
	clear:both;
}

.mobile-none {
	@include break(neutral){
		display: none;
	}
}
div#button_submit {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	letter-spacing: 0;	
}
input.error {
        background-color: #ffd5d5 !important;
        border: 1px solid #ff0000 !important;
}
label.error {
	    color: red;
}
.contact-form-head {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	p {
		color: #d0021b !important;
		font-size: 14px;
	}
}
.form-fields-wrapper {
	display: flex;
	justify-content: space-between;
	@include break(x-small) {
		flex-direction: column;
	}
	.form-section {
		width: 48%;
		@include break(x-small) {
			width: 100%;
		}
	}

}
.checkbox-position-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;

	@include break(x-small) {
		flex-direction: column;
	}
	em {
		font-size: 15px;
		font-family: $Ptserif;
		color: #28180b;
		@include break(mobile) {
			font-size: 13px;
		}
	}
}


/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/

//colors
$color_0: rgba(255, 255, 255, 0.75);
$color_btn: #222;
$color_text: #fff;
$color_shadow: #000;
$color_hover_bg: #ccc;
$color_hover_text: #222;

.slicknav_menu {
	font-size: 16px;
	box-sizing: border-box;
	background: #332315;
	display: none;
	z-index: 200;
	position: fixed;
	top: 0;
	width: 100%;
	left:0;
	height: 51px;
	@include break(mobile){
		display: block;
	}
	.phone-mobile {
		@include fz(16px, 28px, #fff, 300);
		float: left;
		padding-left: 10px;
		padding-top: 6px;
		a {
			@include fz(16px, 28px, #fff, 300);
		}
		span {
			@include break(x-small){
				display: none;
			}
		}
	}
	* {		box-sizing: border-box;	}
	.slicknav_menutxt {
		color: $color_text;
		font-weight: bold;
		text-shadow: 0 1px 3px $color_shadow;
	}
	.slicknav_icon-bar {
		background-color: #f5841f;
	}
}
.slicknav_nav {
	color: $color_text;
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	background: #332315;
	z-index:9999;
	top: -1px;
	position: relative;
	.slicknav_row {
		&:click,
		&:active,
		&:focus {
			color: #ffe49a;
			outline:none;
		}
	}
	a {
			@include fz(16px, 40px, #fff, 400);
			text-align: left;
			text-decoration: none;
			border-bottom: 1px rgba(255,255,255,.2) dashed;
			padding-bottom: 0;
			text-transform: uppercase;			
			padding-left: 20px;
			@include link_trans();
			outline: none!important;
			&:hover {
				color: #ffe49a;
			}
		& + i {
			@include break(mobile) {
				display: none;
			}
		}
	}
	a.btn,
	a.btn:active,
	a.btn:focus {
		background: none;
		border:0;
		border-top: 1px rgba(255,255,255,.2) dashed;
		border-bottom: 1px rgba(255,255,255,.2) dashed;
		text-transform: capitalize;		
		border-radius: 0;
		padding-top: 0;
	}

	a > a {
		//border-top: 0;
	}
	.current-menu-item a {		color: #fff;	}
	.slicknav_txtnode {		margin-left: 15px;	}
	.slicknav_item a {		padding: 0;		margin: 0;	}
	.slicknav_parent-link a {		padding: 0;		margin: 0;	}
}
.slicknav_brand {
	color: $color_text;
	font-size: 18px;
	line-height: 30px;
	padding: 7px 12px;
	height: 44px;
}
.slicknav_btn {
	span {
		display: none;
	}
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	border-radius: 2px;
	width: 32px;
	height: 52px;
	background: url("../img/mobile-button.png") 50% 0px no-repeat;
	background-size: 52px auto;
	float: left;
	margin: 0;
	padding: 0;
	top: 5px;
	left: 20px;
	position: relative;
	outline: none!important;
	@include link_trans();
	&.slicknav_open {
		background-position: 50% -50px;
	}
}

.slicknav_menu {
	*zoom: 1;
	.slicknav_menutxt {
		display: block;
		line-height: 1.188em;
		float: left;
	}
	.slicknav_icon {
		float: left;
    width: 1.125em;
    height: 0.875em;
		margin: 0.188em 0 0 0.438em;
        &:before {
            background: transparent;
            width: 1.125em;
            height: 0.875em;
            display: block;
            content: "";
            position: absolute;
        }
	}
	.slicknav_no-text {
		margin: 0;
	}
	.slicknav_icon-bar {
		display: block;
		width: 1.125em;
		height: 0.125em;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
	}
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.slicknav_nav {
	clear: both;
	ul {
		list-style: none;
		overflow: hidden;
		padding: 10px 0 10px 0;
		margin: 0;
		//background: #0a4490;
		li {
			a {
				text-transform: capitalize;
				line-height: 24px;
				color: #fff;
				border: none;
				font-size: 15px;
				font-weight: 400;
				padding-left: 30px;
				display: block;
				&:hover {
					color: #ffe49a;
				}
			}
		}
	}
	.slicknav_arrow {
		font-size: 0.8em;
		color: #ffe49a;
		font-family: 'FontAwesome';
		float: right;
		margin-right: 20px;
	}
	.slicknav_item {
		cursor: pointer;
		a {
			display: inline;
			border-bottom: none;
			&:click,
			&:active,
			&:focus {
				color: #ffe49a;
				outline:none;
			}

		}
	}
	.slicknav_row {
		display: block;
		&:click,
		&:active,
		&:focus {
			color: #fff;
			outline:none;
		}
	}
	a {		display: block;	}
	.slicknav_parent-link a {
		display: inline;
	}
}


/**     O T H E R       **/
a{color:#00215c; text-decoration:underline;}
a:focus{color:#00215c;text-decoration:none;}
a:hover{text-decoration:none;color:#000}
.center {	text-align: center;	}
.scrolloff {  pointer-events: none;}
.map {
	position: relative;
	padding-bottom: 50%;
	padding-top: 30px;
	height: 0;
	background: #ccc;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
ul.list {
	padding: 0 0 10px 10px;
	margin: 0;
	overflow: hidden;
	li {
		padding-left: 25px;
		position: relative;		
		padding-bottom: 14px;
		@include fz(18px, normal, 400);
		color: rgba(40, 24, 11, .9);
		font-family: $Ptserif;
		@include break(mobile) {
			font-size: 16px;
		}	

	  &::after {
	  	width: 8px;
	  	height: 8px;
	  	position: absolute;
	  	left: 0px;
	  	top: 9px;
	  	border-radius: 50%;
	  	display: block;	  	
	  	background: #a58669;
	  	content: '';
	  }
	}
}
.right {
	float: right;
	@include break(mobile) {
		float: none;
	}
}
.right_20 {
	margin-right: 25px;
}
.paddcontent {
	padding-bottom: 20px;
	@include break(mobile) {
		padding-bottom: 10px;
	}	
}
.smallpadd {
	padding-bottom: 10px;
}

/* Buttons */
.btn {
	@include link_trans();
	@include fz(18px, normal, #fff, 400);
	margin: 0;
	font-family: $Marcellus;
	text-decoration: none;
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 0 16px;
	border-radius: 23.5px;
	text-align: center;
	text-transform: capitalize;
	text-shadow:none;	
	background: #a30014;
	transition: .2s all;
	&:after {
		content: "\f18e";
		font-family: "FontAwesome";
		display: inline;
		font-size: 20px;
		color: #fff;
		margin-left: 11px;
		line-height: 46px;
		transition: .2s all;
		@include break(medium) {
			line-height: 35px;
			font-size: 18px;
		}
		@include break(neutral) {
			font-size: 16px;
			line-height: 32px;
			margin-left: 4px;
		}
		@include break(mobile) {
			font-size: 20px;
			line-height: 46px;
			margin-left: 10px;
		}
	}
	@include break(custom) {

	}				
	@include break(medium) {
		font-size: 16px;
	}
	@include break(neutral) {
		font-size: 14px;
		padding: 0 8px;
	}
	@include break(mobile) {
		font-size: 18px;
		padding: 0 16px;
	}
	@include break(x-small) {
	}
	&:hover {
		background: #cd0c24;
		color: #fff;
		transition: .2s all;

		&:after {
			content: "\f0a9";
			transition: .2s all;
		}
	}	
}
.btn:active, .btn:focus {
	color: #fff;
	top: 4px;
	background-color: #b10d21;
	transition: .2s all;
	&:after {
		content: "\f0a9";
	}
}
.btn-yellow {
	color: #000;
	background-color: #fcc51c;
	text-transform: none;
	&:after {
		color: #000;
	}
	&:hover {
		color: #000;
		background-color: #ffe144;
	}
	&:active, &:focus {
		color: #000;
		background-color: #eed033;
		top: 4px;
	}

}
hr {
	margin: 0 0 5px ;
	padding: 0;
	background: #d7d7d7;
	height: 1px;
	width: 100%;
	border: 0; 
}


.red {	color: #ba0003;}
a[href*="tel"]{text-decoration:none; cursor: default;};
::selection{background: #ededed!important;color:#000000};
::-moz-selection{background: #ededed!important;color:#000000};

#cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 {
	#gallery_cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 {
		width: 40%;
		float: left;
		background: #ffedb7;
	    padding: 6px;
	    box-shadow: 0 9px 14px 0 rgba(0, 0, 0, 0.25);
	    position: sticky;
	    top: 5%;
  		min-height: 2em;
  		@include break(mobile) {
  			float: none;
  			width: 80%;
  			margin: 0 auto;
			top: 15%;
			position: relative;
  		}
	}
	.nivo-controlNav.nivo-thumbs-enabled {
		padding-top: 0;
		width: 55% !important;
		float: right;
		padding: 30px 15px;
		background-color: #fff;
		@include break(neutral) {
			width: 50% !important;
		}
		@include break(mobile) {
			float: none;
			width: 95% !important;
		}
		img {
			@include break(neutral) {
				width: 80px !important;
			}
		}
	}
	.nivo-directionNav {
		a {
			text-decoration: none;
			&:hover {
				&:before {

					opacity: .7;
				}
			}
		}
	}
}
div.nivo-slice img, div.nivo-box img {
	padding: 6px;
}

#cp_widget_45515f92-2a40-445e-8077-a813c860f6c8 .nivo-controlNav.nivo-thumbs-enabled a {
	border: 1px solid transparent;
	transition: .4s all;
	&:hover {
		border: 1px solid #ffedb7;
		 box-shadow: 0 9px 14px 0 rgba(0, 0, 0, 0.25);
		transition: .4s all;
	}
}

.wow {
	visibility: hidden;
}
#facebook-iframe {
	width: 100%;
	max-width: 520px;
	height: 800px;
	display: block;
	margin: 0 auto;
}
div.fb-page {
	height: 600px;
	iframe {
		height: 600px;
	}
}