
@mixin break($point) {
  @if $point == extra-large {
    @media (min-width: 1800px) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 1600px) {
      @content;
    }
  }  @else if $point == custom {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: 1330px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 1329px) {
      @content;
    }
  } @else if $point == neutral {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == not-mobile {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == flag-mobile {
    @media (max-width: 580px) {
      @content;
    }
  } @else if $point == x-small {
    @media (max-width: 479px) {
      @content;
    }
  } @else if $point == very-small {
    @media (max-width: 320px) {
      @content;
    }
  }
}




/* Fonts */
$Oswald: 'Oswald', sans-serif;
$Marcellus: 'Marcellus', serif;
$Ptserif: 'PT Serif', serif;

/* Mixins */
@mixin fz($fz:14px, $lh:22px, $color:#f00, $fw: normal){
	font-size: $fz;
	line-height: $lh;
	color: $color;
	font-weight: $fw;
}

// Adds circles via styles(normally on ::before/::after).
@mixin circle($size: 10px, $color: #fff) {
  width: $size;
  height: $size;
  background: $color;
  border-radius: 50%;
  content: '';
  display: inline-block;
}


@mixin mar_pad($m: 0, $pad: 0){
	margin:$m;
	padding:$pad;
}

@mixin responce_font($fsz, $lht){
	font-size: $fsz;;
	line-height: $lht;;
}

@mixin link_trans($tra: .15s) {
  -webkit-transition: all $tra ease;
     -moz-transition: all $tra ease;
      -ms-transition: all $tra ease;
       -o-transition: all $tra ease;
          transition: all $tra ease;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin inherit() {
  font-size:inherit;
  font-weight:inherit;
  line-height:inherit;
  color: inherit;
  transition: .2s ease-in-out;
}


@keyframes shake {
  0%, 100% {
    margin-left: 0px;
  }
  50% {
    margin-left: -5px;
  }
}
@keyframes shake2 {
  0%, 100% {
    margin-right: 0px;
  }
  50% {
    margin-right: -5px;
  }
}

